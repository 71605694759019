import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import objectPath from "object-path";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function AttachmentIssue(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
//   self: "https://api.atlassian.com/ex/jira/422f27b8-90b9-4ff3-9f3d-14fa9032a196/rest/api/3/attachment/10003"
// id: "10003"
// filename: "Chindia_Târgovişte.png"
// author: {,…}
// self: "https://api.atlassian.com/ex/jira/422f27b8-90b9-4ff3-9f3d-14fa9032a196/rest/api/3/user?accountId=5dddaf127474110e231168dc"
// name: "admin"
// key: "admin"
// accountId: "5dddaf127474110e231168dc"
// emailAddress: "armand@brandweb.ro"
// avatarUrls: {,…}
// displayName: "Armand Bertea"
// active: true
// timeZone: "Europe/Bucharest"
// accountType: "atlassian"
// created: "2019-12-31T12:10:33.167+0200"
// size: 105148
// mimeType: "image/png"
// content: "https://thebrandwebbers.atlassian.net/secure/attachment/10003/Chindia_T%C3%A2rgovi%C5%9Fte.png"
// thumbnail: "https://the

const 
{
  creator,
  author,
  created,
  content,
  filename
} = props.attachment;

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
            objectPath.get(creator, "avatarUrls.48x48") ? (
                <Avatar
                    aria-label="recipe" className={classes.avatar}
                  alt="Project coordonator"
                  src={objectPath.get(author, "author.avatarUrls.48x48")}
                />
              ) : (
                <Avatar alt="NatProject coordonatoracha" aria-label="recipe" className={classes.avatar}> {author.name[0]} </Avatar>
              )
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={filename}
        subheader={created}
      />
      <CardMedia
        className={classes.media}
        image={content}
        title={"Paella dish"}
      />
    </Card>
  );
}