import React, { Component } from "react";
import Moment from "react-moment";
import objectPath from "object-path";
import {
  TextField,
  Button,
  ListItemText,
  Avatar,
  List,
  ListItem,
  CircularProgress,
  LinearProgress
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

class CommentSection extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.lastItemRef = React.createRef();
    this.addCommentButtonRef = React.createRef();
    this.prevFirstElemRef = React.createRef();
    this.state = {};
  }
  componentDidMount = () => {
    if (this.props.scrollComment === "bottom") {
      this.scrollToBottom();
    } else {
      if (this.props.comments.length > 8) {
        this.scrollToFirstPrevElem();
      }
    }
  };
  scrollToBottom = () => {
    this.containerRef.current.scrollTo(0, this.lastItemRef.current.offsetTop);
  };
  scrollToFirstPrevElem = () => {
    if (this.prevFirstElemRef.current) {
      console.log(this.prevFirstElemRef);
      this.containerRef.current.scrollTo(
        0,
        this.prevFirstElemRef.current.offsetTop
      );
    }
  };
  scrollToTop = () => {
    // console.log(this.lastItemRef.current.offsetTop)
    this.containerRef.current.scrollTo(0, 0);
  };
  onScroll = () => {
    if (this.containerRef.current.scrollTop === 0) {
      this.props.showMoreComments();
    }
  };
  componentDidUpdate = prevProps => {
    if (
      prevProps.isFetchingShowMoreComments !==
        this.props.isFetchingShowMoreComments &&
      this.props.isFetchingShowMoreComments === false
    ) {
      setTimeout(() => {
        this.scrollToFirstPrevElem();
      }, 100);
    }
  };
  render() {
    const {
      comments,
      classesListComment,
      expand,
      isFetchingAddComment,
      classes,
      commentText,
      isFetchingShowMoreComments,
      totalComments
    } = this.props;
    return (
      <>
        <List
          className={classesListComment.root_comment}
          style={
            expand
              ? {
                  height: "auto",
                  maxHeight: "500px"
                }
              : {}
          }
          ref={this.containerRef}
          onWheel={e => {
            if (
              isFetchingShowMoreComments !== true &&
              e.deltaY < 0 &&
              totalComments > comments.length
            ) {
              this.onScroll();
            }
          }}
          onTouchStart={e => {
            this.setState({
              touch_st: e.touches[0].clientY
            });
          }}
          onTouchEnd={e => {
            let te = e.changedTouches[0].clientY;
            let ts = this.state.touch_st || 0;
            if (
              isFetchingShowMoreComments !== true &&
              ts > te+1 &&
              totalComments > comments.length
            ) {
              this.onScroll();
            }
          }}
        >
          {isFetchingShowMoreComments === true ? (
            <div className={classes.liniarLoader}>
              <LinearProgress />
              <br />
              <LinearProgress color="secondary" />
            </div>
          ) : (
            ""
          )}
          {comments.length === 0 ? (
            <ListItem alignItems="flex-start">
              <ListItemText primary={"No comments."} />
            </ListItem>
          ) : (
            comments.map((comment, index) => {
              return (
                <ListItem
                  alignItems="flex-start"
                  key={comment.id}
                  className={classesListComment.comment}
                  ref={index === 8 ? this.prevFirstElemRef : null}
                >
                  {objectPath(comment, "author.avatarUrls.48x48") ? (
                    <Avatar
                      alt="user_pic"
                      src={comment.author.avatarUrls["48x48"]}
                    />
                  ) : (
                    <Avatar alt="user_pic">
                      {comment["author"]["displayName"]
                        ? comment["author"]["displayName"]
                            .split(" ")
                            .map(n => {
                              return n.slice(0, 1).toUpperCase();
                            })
                            .join(" ")
                        : "Empty Name"}
                    </Avatar>
                  )}
                  <div className={classesListComment.content}>
                    <div className={classesListComment.author}>
                      {comment["author"]["displayName"] || "error"}
                    </div>
                    <div className={classesListComment.metadata}>
                      <div>
                        <Moment
                          format="dddd YYYY-MM-DD HH:mm:ss"
                          date={comment.date}
                        />
                      </div>
                    </div>
                    <div className={classesListComment.text}>
                      <>
                        {comment.items.map(item => {
                          if (item.type === "text") {
                            return <p> {item.text} </p>;
                          }
                        })}
                      </>
                    </div>
                  </div>
                </ListItem>
              );
            })
          )}
          <div ref={this.lastItemRef}></div>
        </List>
        <div className={classes.addCommentSection}>
          <TextField
            id="outlined-full-width"
            label="Comment"
            helperText="Visible to client"
            fullWidth
            margin="normal"
            variant="outlined"
            multiline={true}
            rowsMax={4}
            rows={4}
            onChange={e => {
              this.props.setCommentText(e.target.value);
            }}
            value={commentText}
            disabled={isFetchingAddComment}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.addCommentButton}
            onClick={this.props.handeleAddComment}
            disabled={
              isFetchingAddComment || commentText.replace(/\s/g, "").length == 0
            }
            ref={this.addCommentButtonRef}
          >
            {isFetchingAddComment === true ? (
              <CircularProgress
                style={{ height: "18px", width: "18px", zIndex: "5" }}
                color="secondary"
                className={classes.leftIcon}
              />
            ) : (
              <EditIcon className={classes.leftIcon}></EditIcon>
            )}
            Post Comment
          </Button>
        </div>
      </>
    );
  }
}
export default CommentSection;
