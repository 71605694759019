import React, { Component, Children } from 'react';

let interval = 1;

export default class Timer extends Component {
  constructor(props) {
      
    super(props);
    this.state = {
        number:0,
        minutes: '00 : 00 : 00',
    };
    
  }
  componentDidMount = () => {
    this.resetTimer(this.props.time);
  }
  componentWillUnmount() {
    const {number, minutes} = this.state;
    this.props.getTimePassed(number, minutes);
    clearInterval(interval);
    this.setState({number:0, minutes:0});
  }
  resetTimer = (val = 0) => {
      if(interval != null)
      {
        clearInterval(interval);
      }
    this.setState({number:val}, () => {
        interval = setInterval(() => {
            let time = this.state.number + 1;
            const hours = Math.floor(time/3600);
            time = time - hours*3600;
            const min = Math.floor(time/60);
            time = time - min*60;
            const s = Math.floor(time);
            const minutes = `${hours < 10 ? '0'+hours : hours} : ${min < 10 ? '0'+min : min} : ${s < 10 ? '0'+s : s}`;
            this.setState({
                number:this.state.number + 1,
                minutes:minutes
            });
          }, 1000);   
    });
  }
  componentDidUpdate = prevProps => {
      if(prevProps.time !== this.props.time)
      {

        this.resetTimer(this.props.time);
      }
  }
  render() {
    return (
      <>
        {
            this.state.minutes
        }      
      </>
    );
  }
}
