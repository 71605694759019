import React, { Component } from "react";
import * as auth from "../../store/ducks/auth.duck";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import objectPath from "object-path";
import {removeStorage} from '../../../_metronic/utils/utils';
class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logout_fail: false
    }
  }
  
  componentDidMount() {
    removeStorage('jira_access_token');
    if(1)
    {
      this.props.logout();
    }
    else
    {
      this.setState({logout_fail:true});
    }
  }

  render() {
    const { hasAuthToken } = this.props;

    return this.state.logout_fail===true ? <Redirect to="/auth"/> : hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />;
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(objectPath(auth, 'user.refresh_token') && objectPath.get(auth, 'user.cloud_id')) }),
  auth.actions
)(Logout);
