import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LayoutSplashScreen } from "./LayoutContext";
import * as builder from "../ducks/builder";
import LoadServerDataPage from '../../app/LoadServerDataPage';
/**
 * Used to synchronize current layout `menuConfig`, `layoutConfig` and
 * `htmlClassService` with redux store.
 */

export default function LayoutInitializer({
  children,
  menuConfig,
  layoutConfig,
  htmlClassService,
  LoadingPage,
  location
}) {
  
  const dispatch = useDispatch();
  const builderState = useSelector(({ builder }) => builder);
  const [loading_data_key, set_loading_data_key] = useState(1);

  useEffect(() => {
    dispatch(builder.actions.setMenuConfig(menuConfig));
  }, [dispatch, menuConfig]);

  useEffect(() => {
    if (layoutConfig.demo !== builderState.layoutConfig.demo) {
      dispatch(builder.actions.setLayoutConfigs(layoutConfig));
    }
  }, [dispatch, builderState, layoutConfig]);

  useEffect(() => {
    dispatch(builder.actions.setHtmlClassService(htmlClassService));
  }, [dispatch, htmlClassService]);

  useEffect(() => {
    set_loading_data_key(Date.now());
  }, [location.pathname]);
  return (
    <>
      <LoadServerDataPage
        key={loading_data_key}
        setPageLoading={
          (isLoading) => {
            dispatch(builder.actions.setPageLoading(isLoading));
          }
        }
        setMenuConfig = {
          (menuConfig) => {
            dispatch(builder.actions.setMenuConfig(menuConfig));
          }
        }
        setContentPageConfig = {
          
          (pageContentConfig) => {
            dispatch(builder.actions.setContentPageConfig(pageContentConfig));
          }
        }
      />

      {
      htmlClassService === builderState.htmlClassServiceObjects &&
        LoadingPage !== true
        ?
        (<>
          {/* Render content when `htmlClassService` synchronized with redux store. */}
          {children}
        </>
        ) :
        (<LayoutSplashScreen visible={LoadingPage}/>)
      }
    </>
  );
}
