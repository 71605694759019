/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { connect, useDispatch } from "react-redux";
import { useEffect } from "react";
import objectPath from "object-path";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import { LayoutContextConsumer, useSubHeaderDispatch } from "../LayoutContext";
import * as builder from "../../ducks/builder";
// import ProjectListSubHeader from '../'
import BreadCrumbs from "./components/BreadCrumbs";
import axios from "axios";

import HeaderRightSubMenuListDashboard from "./HeaderRighSubmenuList";
import HeaderRightSubMenuListReports from "./HeaderRightSubMenuListReports";

class SubHeader extends React.Component {
  constructor(props) {
    super(props);
    // this.subHeaderRef = React.createRef();
    this.state = {
      no_wrap_subheader: false
    };
  }
  componentDidMount = () => {
    this.setState({
      no_wrap_subheader: this.props.location.pathname === "/dashboard"
    });
  };
  componentDidUpdate = prevProps => {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        no_wrap_subheader: this.props.location.pathname === "/dashboard"
      });
    }
  };
  render() {
    const {
      subheaderCssClasses,
      subheaderContainerCssClasses,
      subheaderMobileToggle
    } = this.props;
    const pageName = this.props.location.pathname.slice(
      1,
      this.props.location.pathname.length
    );

    return (
      <div
        id="kt_subheader"
        className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}
      >
        <div className={`kt-container ${subheaderContainerCssClasses}`}>
          <div
            className="kt-subheader__main"
            style={
              this.state.no_wrap_subheader === true
                ? {
                    flexWrap: "nowrap",
                    overflow: "hidden"
                  }
                : {}
            }
          >
            {subheaderMobileToggle && (
              <button
                className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                id="kt_subheader_mobile_toggle"
              >
                <span />
              </button>
            )}

            <LayoutContextConsumer>
              {({
                subheader: { title, breadcrumb, hideBreadcrumb, submenu_items }
              }) => (
                <>
                  <h3 className="kt-subheader__title">{title}</h3>
                  {hideBreadcrumb === true ? (
                    ""
                  ) : (
                    <BreadCrumbs items={breadcrumb} />
                  )}

                  <span className="kt-subheader__separator kt-subheader__separator--v" />

                  {Array.isArray(submenu_items) && submenu_items.length > 0 ? (
                    pageName === "dashboard" ? (
                      <>
                        <HeaderRightSubMenuListDashboard
                          items={submenu_items}
                          breadcrumb={breadcrumb}
                          pageName={pageName}
                        />
                      </>
                    ) : pageName === "reports" ? (
                      <>
                        <HeaderRightSubMenuListReports items={submenu_items} />
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </>
              )}
            </LayoutContextConsumer>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  config: store.builder.layoutConfig,
  // header_items: objectPath.get(store, 'builder.menuConfig.header.items') || [],
  subheaderCssClasses: builder.selectors.getClasses(store, {
    path: "subheader",
    toString: true
  }),
  subheaderContainerCssClasses: builder.selectors.getClasses(store, {
    path: "subheader_container",
    toString: true
  })
});

export default withRouter(connect(mapStateToProps)(SubHeader));