import React from "react";
import Moment from "react-moment";
import "moment-timezone";
import objectPath from "object-path";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ScheduleIcon from "@material-ui/icons/Schedule";

import {
  Menu,
  Button,
  MenuItem,
  ListItemText,
  Avatar,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  TextField,
  DialogContent,
  LinearProgress,
  CircularProgress,
  Tooltip
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { returnNewToken, status_ids_ } from "../../../../_metronic/utils/utils";
import "../../../../_metronic/_assets/css/stylesheet.css";

import AttachmentIssue from "./AttachmentIssue";
import CommentSection from "./CommentSection";
import axios from "axios";
//STYLES....
const StyledMenuItem4 = withStyles(theme => ({
  root: {
    "&:focus, &active_item": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);
const StyledMenu4 = withStyles({
  paper: {
    border: "1px solid #d3d4d5"
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    {...props}
  />
));
const useStylesCommentList = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    maxHeight: "20px",
    "-webkit-transition": "all 0.2s",
    "-moz-transition": "all 0.2s",
    "-ms-transition": "all 0.2s",
    "-o-transition": "all 0.2s",
    transition: "all 0.2s"
  },
  root_comment: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    maxHeight: "20px",
    "-webkit-transition": "all 0.2s",
    "-moz-transition": "all 0.2s",
    "-ms-transition": "all 0.2s",
    "-o-transition": "all 0.2s",
    transition: "all 0.2s",
    "overflow-y": "auto",
    "overflow-x": "hidden",
    "&::-webkit_scrollbar": {
      width: "12px",
      backgroundColor: "#F5F5F5"
    },
    "&::-webkit_scrollbar_thumb": {
      borderRadius: "10px",
      WebkitBoxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#4285F4"
    }
  },
  chip: {
    marginRight: theme.spacing(1)
  },
  section1: {
    margin: theme.spacing(3, 2)
  },
  section3: {
    margin: theme.spacing(3, 1, 1)
  },
  button: {
    margin: theme.spacing(1),
    fontSize: "15px",
    margin: "3px 0"
  },
  //
  comment: {
    position: "relative",
    background: "0 0",
    marginTop: theme.spacing(2),
    paddingTop: "5px",
    border: "none",
    borderTop: "none",
    lineHeight: "1.2"
  },
  content: { marginLeft: "12px" },
  author: {
    fontSize: "1em",
    color: "#646c9a",
    fontWeight: "700",
    display: "inline"
  },
  metadata: {
    display: "inline-block",
    marginLeft: ".5em",
    color: "rgba(0,0,0,.4)",
    fontSize: ".875em",
    ">*": { display: "inline-block", margin: "0 .5em 0 0" }
  },
  text: {
    margin: ".25em 0 .5em",
    fontSize: "1em",
    wordWrap: "break-word",
    color: "rgba(0,0,0,.87)",
    lineHeight: "1.3"
  },
  //
  exp_cont_relative: {
    position: "relative",
    height: "17px"
  },
  expand_top_container: {
    position: "absolute",
    left: 0,
    top: "5px",
    width: "100%",
    height: "50px",
    padding: "16px 0px",
    cursor: "pointer",
    padding: "0 15px",
    zIndex: "4"
  },
  "css-1vqao0l": {
    WebkitBoxAlign: "baseline",
    alignItems: "baseline",
    boxSizing: "border-box",
    display: "inline-flex",
    fontSize: "inherit",
    fontStyle: "normal",
    fontWeight: "normal",
    maxWidth: "100%",
    textAlign: "center",
    whiteSpace: "nowrap",
    cursor: "pointer",
    height: "2.28571em",
    lineHeight: "2.28571em",
    verticalAlign: "middle",
    width: "100%",
    color: "rgb(80, 95, 121) !important",
    borderWidth: "0px",
    textDecoration: "none",
    background: "rgba(9, 30, 66, 0.04)",
    borderRadius: "3px",
    padding: "0px 8px",
    transition:
      "background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s",
    outline: "none !important"
  },
  "css-3ael86": {
    WebkitBoxAlign: "baseline",
    alignItems: "baseline",
    boxSizing: "border-box",
    display: "inline-flex",
    fontSize: "inherit",
    fontStyle: "normal",
    fontWeight: "normal",
    maxWidth: "100%",
    textAlign: "center",
    whiteSpace: "nowrap",
    cursor: "pointer",
    height: "2.28571em",
    lineHeight: "2.28571em",
    verticalAlign: "middle",
    width: "100%",
    color: "rgb(80, 95, 121) !important",
    borderWidth: "0px",
    textDecoration: "none",
    background: "rgba(9, 30, 66, 0.04)",
    borderRadius: "3px",
    padding: "0px 8px",
    transition:
      "background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s",
    outline: "none !important",
    zIndex: "5",
    "&:hover": {
      WebkitBoxAlign: "baseline",
      alignItems: "baseline",
      boxSizing: "border-box",
      display: "inline-flex",
      fontSize: "inherit",
      fontStyle: "normal",
      fontWeight: "normal",
      maxWidth: "100%",
      textAlign: "center",
      whiteSpace: "nowrap",
      cursor: "pointer",
      height: "2.28571em",
      lineHeight: "2.28571em",
      verticalAlign: "middle",
      width: "100%",
      color: "rgb(80, 95, 121) !important",
      borderWidth: "0px",
      textDecoration: "none",
      background: "rgba(9, 30, 66, 0.08)",
      borderRadius: "3px",
      padding: "0px 8px",
      transition:
        "background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s",
      outline: "none !important"
    }
  },
  "css-t5emrf": {
    WebkitBoxAlign: "center",
    alignItems: "center",
    alignSelf: "center",
    maxWidth: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    opacity: "1",
    flex: "1 1 auto",
    margin: "0px 4px",
    overflow: "hidden",
    transition: "opacity 0.3s ease 0s"
  }
}));
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: "inline"
  },
  subtitle_container: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  description: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    paddingTop: "5px"
    // paddingLe
  },
  subtitle: {
    paddingTop: "10px"
  },
  button_container: {
    padding: "1px"
  },
  divider: {
    marginTop: "5px",
    marginBottom: "16px"
  },
  liniarLoader: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  //.....
  addCommentSection: {
    width: "100%",
    padding: "10px 15px"
  },
  addCommentBUtton: {
    margin: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  leftIcon: {
    marginRight: theme.spacing(2)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  due_date_container: {
    width: "109px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&> div": {
      textAlign: "center",
      width: "90px"
    }
  },
  toolprit_issue: {
    top: "-15px",
    padding: "5px 10px",
    fontSize: "12px"
  },
  display_none: {
    display: "none"
  }
}));

//.....
let select_opt = -1;
export default function IssuesItem(props) {
  // Example 1
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [commentText, setCommentText] = React.useState("");
  const [isFetchingAddComment, setIsFetchingAddComment] = React.useState(false);
  const [commentLenLimit, setCommentLenLimit] = React.useState(5);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [scrollComment, setScrollComment] = React.useState("bottom");
  const [descriptionItems, setDescriptionItems] = React.useState([
    { type: "text", text: "No description." }
  ]);
  const [
    open_add_comment_req_info,
    set_open_add_comment_req_info
  ] = React.useState(false);
  const [req_info_comment, set_req_info_comment] = React.useState({
    value: "",
    isFetching: false,
    open: false
  });

  //issue info
  const expand = props.expand;
  const issue = props.issue || {};
  const summary = objectPath.get(issue, "fields.summary") || "Sumay undefined";
  const project_name =
    objectPath.get(issue, "fields.project.name") || "Project name undefined";
  const creator = objectPath.get(issue, "fields.creator");
  const priority = objectPath.get(issue, "fields.priority") || {};
  const status_name = status_ids_[objectPath.get(issue, "fields.status.id")] || "Ready";
  const description = objectPath.get(issue, "fields.description.content") || [
    { type: "text", text: "No description." }
  ];
  const id = objectPath.get(issue, "id") || null;
  const duedate = objectPath.get(issue, "fields.duedate") || null;
  const { custom_statuses_select } = props;
  const {
    isFetchingFiles,
    isFetchingComments,
    isFetchingShowMoreComments,
    isFetchingStatusChange,
    is_fetching_req_info_comment,
    comments,
    files
  } = props;
  const deadline_rel_for_today =
    duedate != null
      ? Math.floor(
          (new Date().getTime() - new Date(duedate).getTime()) /
            (1000 * 3600 * 24)
        ) * -1
      : undefined;
  let display_text = "";
  if (deadline_rel_for_today >= 0) {
    if (deadline_rel_for_today === 1) {
      display_text = "Tomorrow";
    } else {
      if (deadline_rel_for_today === 0) {
        display_text = "Today";
      } else {
        display_text = Math.abs(deadline_rel_for_today) + " days left";
      }
    }
  } else {
    if (deadline_rel_for_today === -1) {
      display_text = "Yesterday";
    } else {
      display_text = Math.abs(deadline_rel_for_today) + " days ago";
    }
  }
  const [window_innerWidth, set_window_innerWidth] = React.useState(window.innerWidth); 
  const dropdown_options = custom_statuses_select.map((item, index) => {
    return (
      <StyledMenuItem4
        key={index+'_'+id}
        onClick={() => {
          if (item.name !== status_name) {
            select_opt = item.name;
            if (item.name === "Require Info") {
              set_req_info_comment({ ...req_info_comment, open: true });
            } else {
              setOpenDialog(true);
            }
          }
          setAnchorEl4(null);
        }}
        selected={status_name === item.name}
      >
        <ListItemText primary={item.name} />
      </StyledMenuItem4>
    );
  });
  const { refresh_token, cloud_id } = props;
  //styles
  const classes = useStyles();
  const classesListComment = useStylesCommentList();

  function handleClickStatusSelect(event) {
    setAnchorEl4(event.currentTarget);
  }
  function handleCloseDropDown() {
    setAnchorEl4(null);
  }
  const handleDialogAnswear = yes => {
    if (
      yes === true &&
      custom_statuses_select.find(item => item.name === select_opt)
    ) {
      props.onChangeStatus(select_opt);
    }
    select_opt = -1;
    setOpenDialog(false);
  };
  const handleDialogAnswear_req_info = yes => {
    if (yes === true && select_opt === "Require Info") {
      props.require_info_issue(req_info_comment.value);
    }
    select_opt = -1;
    set_req_info_comment({ value: "", isFetching: false, open: false });
  };
  const handeleAddComment = () => {
    if (
      isFetchingAddComment === true ||
      commentText == "" ||
      commentText.replace(/\s/g, "").length == 0
    )
      return;
    setIsFetchingAddComment(true);

    returnNewToken(refresh_token)
      .then(access_token => {
        if (access_token) {
          const payload = {
            access_token: access_token,
            cloud_id: cloud_id,
            comment: props.convertCommentJiraFormat(commentText),
            task_id: id
          };
          axios.post("add-comment.php", payload).then(({ data }) => {
            if (data.id) {
              setScrollComment("bottom");
              props.insertComment(data, id);
              setCommentText("");
              setIsFetchingAddComment(false);
            } else {
              console.log("Server: Comment could not be added.");
              setIsFetchingAddComment(false);
            }
          });
        } else {
          console.log("Erro: empty access token.");
          setIsFetchingAddComment(false);
        }
      })
      .catch(err => {
        setIsFetchingAddComment(false);
        console.log(err, "EROR: getting access token.");
      });
  };
  function incrementLimit() {
    if (comments.length > commentLenLimit) {
      if (commentLenLimit + 10 > comments.length) {
        setCommentLenLimit(comments.length);
      } else {
        setCommentLenLimit(commentLenLimit + 10);
      }
    }
  }
  React.useEffect(() => {
    if (!expand) {
      setScrollComment("bottom");
      //set description
      let comment_text = {
        items: []
      };
      props.read_content(description, comment_text);
      setDescriptionItems(comment_text.items);
    }
  }, [expand]);
  React.useEffect(() => {
    if (window.innerWidth !== window_innerWidth) {
      set_window_innerWidth(window.innerWidth);
    }
  }, [window.innerWidth]);
  return (
    <div className="kt-widget_container_">
      <div className="kt-widget4__item kt-widget__dashboard">
        <div className="kt-widget4__pic kt-widget4__pic--pic ">
          {objectPath.get(creator, "avatarUrls.48x48") ? (
            <Avatar
              alt="Project coordonator"
              src={objectPath.get(creator, "avatarUrls.48x48")}
            />
          ) : (
            <Avatar alt="NatProject coordonatoracha">
              {creator.name
                .split(" ")
                .map(n => {
                  return n.slice(0, 1).toUpperCase();
                })
                .join(" ")}
            </Avatar>
          )}
        </div>
        <div className="kt-widget4__info ">
          <div
            className="kt-widget4__username"
            onClick={expand ? props.onCollapseIssue : props.onExpandIssue}
          >
            {summary}
          </div>
          {/* <p className="kt-widget4__title"></p> */}
          <div className="kt-widget4__text ">{project_name}</div>
        </div>
        {window_innerWidth > 700 ? (
          <div className="kt-widget4_action_btns_container desktop_btns_issue_container_">
            <Tooltip
              title={display_text}
              placement="top"
              classes={
                deadline_rel_for_today === undefined
                  ? { tooltip: classes.display_none }
                  : { tooltip: classes.toolprit_issue }
              }
            >
              <div
                className={classes.due_date_container}
                style={deadline_rel_for_today < 0 ? { color: "#ea4444" } : {}}
              >
                {duedate != null ? (
                  <>
                    <ScheduleIcon />
                    <div>
                      <Moment format="MMM. DD, YYYY" date={duedate} />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </Tooltip>
            <div className="ic_container_">
              <Tooltip
                title={objectPath.get(priority, "name") || "priority"}
                placement="top"
                classes={{ tooltip: classes.toolprit_issue }}
              >
                <img
                  src={objectPath.get(priority, "iconUrl") || ""}
                  alt="icon_priority"
                ></img>
              </Tooltip>
            </div>
            <div className="kt-section__content">
              <div>
                <Button
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="contained"
                  color="primary"
                  onClick={handleClickStatusSelect}
                  disabled={isFetchingStatusChange}
                  className="after_dropdown_"
                  disabled={isFetchingStatusChange}
                >
                  {isFetchingStatusChange === true ? (
                    <CircularProgress
                      style={{ height: "20px", width: "20px", zIndex: "5" }}
                      color="primary"
                    />
                  ) : (
                    <>
                      {" "}
                      {status_name} <ExpandMoreIcon />{" "}
                    </>
                  )}
                </Button>
                <StyledMenu4
                  id="customized-menu"
                  anchorEl={anchorEl4}
                  keepMounted
                  open={Boolean(anchorEl4)}
                  onClose={handleCloseDropDown}
                >
                  {dropdown_options}
                </StyledMenu4>
              </div>
            </div>
            <a
              style={{ width: "55px", height: "32px", display: "inline-table" }}
              className="btn btn-sm btn-label-dark"
              onClick={() => props.startTaskButton(issue.id)}
            >
              {isFetchingStatusChange === true ? (
                <CircularProgress
                  style={{ height: "10px", width: "10px", zIndex: "5" }}
                  color="primary"
                />
              ) : (
                <>Start</>
              )}

              {/* {props.isFetchingRunningTask === true ? (
                    <CircularProgress
                      style={{ height: "20px", width: "auto" }}
                      color="secondary"
                    />
                  ) : (
                    <> Start </>
                  )} */}
            </a>
          </div>
        ) : (
          <div className="kt-widget4_action_btns_container mobile_btns_issue_container_">
            <Tooltip
              title={display_text}
              placement="top"
              classes={
                deadline_rel_for_today === undefined
                  ? { tooltip: classes.display_none }
                  : { tooltip: classes.toolprit_issue }
              }
            >
              <div
                className={classes.due_date_container}
                style={deadline_rel_for_today < 0 ? { color: "#ea4444" } : {}}
              >
                <div className="ic_container_">
                  <Tooltip
                    title={objectPath.get(priority, "name") || "priority"}
                    placement="top"
                    classes={{ tooltip: classes.toolprit_issue }}
                  >
                    <img
                      src={objectPath.get(priority, "iconUrl") || ""}
                      alt="icon_priority"
                    ></img>
                  </Tooltip>
                </div>

                <div style={{ width: "102px" }}>
                  {duedate != null ? (
                    <Moment format="MMM. DD, YYYY" date={duedate} />
                  ) : (
                   <div style={{width:"100%", paddingRight:"30px"}}> "-"</div>
                  )}
                </div>
              </div>
            </Tooltip>
            <div className="kt-section__content">
              <div>
                <Button
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="contained"
                  color="primary"
                  onClick={handleClickStatusSelect}
                  disabled={isFetchingStatusChange}
                  className="after_dropdown_"
                  disabled={isFetchingStatusChange}
                >
                  {isFetchingStatusChange === true ? (
                    <CircularProgress
                      style={{ height: "20px", width: "20px", zIndex: "5" }}
                      color="primary"
                    />
                  ) : (
                    <>
                      {" "}
                      {status_name} <ExpandMoreIcon />{" "}
                    </>
                  )}
                </Button>
                <StyledMenu4
                  id="customized-menu"
                  anchorEl={anchorEl4}
                  keepMounted
                  open={Boolean(anchorEl4)}
                  onClose={handleCloseDropDown}
                >
                  {dropdown_options}
                </StyledMenu4>
              </div>
            </div>
            <a
              style={{ display: "inline-table" }}
              className="btn btn-sm btn-label-dark"
              onClick={() => props.startTaskButton(issue.id)}
            >
              {isFetchingStatusChange === true ? (
                <CircularProgress
                  style={{ height: "10px", width: "10px", zIndex: "5" }}
                  color="primary"
                />
              ) : (
                <>Start</>
              )}

              {/* {props.isFetchingRunningTask === true ? (
                    <CircularProgress
                      style={{ height: "20px", width: "auto" }}
                      color="secondary"
                    />
                  ) : (
                    <> Start </>
                  )} */}
            </a>
          </div>
        )}

        <Dialog
          fullScreen={fullScreen}
          open={openDialog}
          onClose={() => {
            handleDialogAnswear(false);
          }}
          aria-labelledby="responsive-dialog-title"
          className="dialog_box_dashboard"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Are you sure?"}
          </DialogTitle>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => handleDialogAnswear(false)}
              color="primary"
            >
              No
            </Button>
            <Button
              autoFocus
              onClick={() => handleDialogAnswear(true)}
              color="primary"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullScreen={fullScreen}
          open={req_info_comment.open}
          onClose={() => {
            // handleDialogAnswear(false);
          }}
          aria-labelledby="responsive-dialog-title"
          className="dialog_box_dashboard"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Leave your feedback"}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Your comment"
              type="text"
              fullWidth
              variant="outlined"
              multiline={true}
              rowsMax={4}
              rows={4}
              disabled={is_fetching_req_info_comment}
              onChange={e => {
                set_req_info_comment({
                  ...req_info_comment,
                  value: e.target.value
                });
              }}
              value={req_info_comment.value}
            />
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                handleDialogAnswear_req_info(false);
              }}
              color="primary"
              disabled={is_fetching_req_info_comment}
            >
              Cancel
            </Button>
            <Button
              autoFocus
              onClick={() => {
                handleDialogAnswear_req_info(true);
              }}
              color="primary"
              disabled={
                is_fetching_req_info_comment ||
                !props.validateCommentText(req_info_comment.value)
              }
            >
              {is_fetching_req_info_comment === true ? (
                <CircularProgress
                  style={{ height: "17px", width: "17px", zIndex: "5" }}
                  color="primary"
                />
              ) : (
                <>Proceed</>
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div
        className="exp_container_task"
        style={
          expand
            ? {
                height: "auto",
                maxHeight: "3000px"
              }
            : {}
        }
      >
        <div className={classes.root} id={"exp_container_task_issue_" + id}>
          <div className={classes.description}>
            <Divider className={classes.divider} />
            {descriptionItems.map(item => (
              <p className="kt-section__sub"> {item.text}</p>
            ))}
          </div>
          <div className={classes.subtitle_container}>
            <Grid container alignItems="center">
              <Grid item xs className={classes.subtitle}>
                <Typography gutterBottom variant="h6">
                  Comments
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Divider variant="middle" />
          {isFetchingComments === true ? (
            <div className={classes.liniarLoader}>
              <LinearProgress />
              <br />
              <LinearProgress color="secondary" />
            </div>
          ) : (
            <>
              <div className={classesListComment.exp_cont_relative}>
                {1 + 1 === 3 && comments.length > commentLenLimit ? (
                  <div className={classesListComment.expand_top_container}>
                    <button
                      onClick={incrementLimit}
                      className={classesListComment["css-3ael86"]}
                    >
                      <span className={classesListComment["css-1vqao0l"]}>
                        <span className={classesListComment["css-t5emrf"]}>
                          <a>Show more ({comments.length - commentLenLimit})</a>
                        </span>
                      </span>
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <CommentSection
                key={
                  "comment_section_" +
                  id +
                  "_" +
                  (comments.length > 0 ? comments[comments.length - 1].id : 0)
                }
                scrollComment={scrollComment}
                expand={expand}
                comments={comments}
                classesListComment={classesListComment}
                isFetchingAddComment={isFetchingAddComment}
                classes={classes}
                commentText={commentText}
                setCommentText={value => {
                  setCommentText(value);
                }}
                handeleAddComment={handeleAddComment}
                showMoreComments={() => {
                  setScrollComment("top");
                  console.log("nn");
                  props.showMoreComments(id, comments.length, 8);
                }}
                isFetchingShowMoreComments={props.isFetchingShowMoreComments}
                totalComments={props.totalComments}
              />
            </>
          )}
          <div className={classes.subtitle_container}>
            <Grid container alignItems="center">
              <Grid item xs className={classes.subtitle}>
                <Typography gutterBottom variant="h6">
                  Files
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Divider variant="middle" />

          {isFetchingFiles === true ? (
            <div className={classes.liniarLoader}>
              <LinearProgress />
              <br />
              <LinearProgress color="secondary" />
            </div>
          ) : (
            <List
              className={classesListComment.root}
              style={
                expand
                  ? {
                      height: "auto",
                      maxHeight: "3000px"
                    }
                  : {}
              }
            >
              {files.length === 0 ? (
                <ListItem alignItems="flex-start">
                  <ListItemText primary={"No files."} />
                </ListItem>
              ) : (
                files.map((file, index) => (
                  // <AttachmentIssue
                  // attachment = {file}
                  // key={file.id}
                  // />
                  <ListItem alignItems="flex-start" key={file.id}>
                    <a href={file.content} target="_blank">
                      <ListItemText primary={file.filename || "error"} />
                    </a>
                  </ListItem>
                ))
              )}
            </List>
          )}
        </div>
      </div>
      <div></div>
    </div>
  );
}
