import React, { useMemo, useState } from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import objectPath from "object-path";
import { Dropdown, DropdownButton, Alert, Modal } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { Divider, Button, Typography, Dialog } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Avatar from "@material-ui/core/Avatar";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";

import { Spinner } from "react-bootstrap";
import ButtonB from "react-bootstrap/Button";

import axios from "axios";
import { returnNewToken, status_ids_ } from "./../../../_metronic/utils/utils";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { metronic } from "../../../_metronic";
import IssuesItem from "./reusableFields/IssuesItem";
import Timer from "./reusableFields/Timer";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";

import PropTypes, { array } from "prop-types";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import { amber, green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import test_img from "./portrait_view.png";
import { getStorage } from './../../../_metronic/utils/utils';

//WARNING SNACKBAR MESSAGE...
const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
}));
const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};
function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}
MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};
//....DIALOG
const styles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    width: "652px",
    padding: "10px 35px 0 35px"
  },
  dialocContent: {
    width: "100%",
    padding: "25px 35px 15px 35px!important"
  },
  divider: {
    width: "100%",
    height: "16px"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  title: {
    marginTop: "5px",
    marginBottom: "8px"
  },
  action_inputs_cont: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "25px 5px"
  },
  select: {
    // width: "200px",
  },
  dropdown_button: {
    width: "100%"
  },
  dropdown_button_child: {
    width: "100%"
  },
  undeInputText: {
    fontSize: "0.85rem",
    margin: "8px 4px 0"
  },
  refresh_tasks_button: {
    width: "100px",
    display: "flex",
    justifyContent: "flex-end",
    height: "100%",
    alignItems: "center",
    "spinner-border": {
      width: "15px",
      height: "15px",
      border: "0.15em solid currentColor"
    },
    "&>button": {
      width: "80px",
      height: "30px"
    },
    "&>div": {
      fontSize: "18px",
      fontWeight: "bold",
      width: "auto",
      height: "30px",
      margin: "0",
      padding: "5px",
      textAlign: "center!important",
      display: "flex",
      justifyContent: "center",
      alignItems: "fle-end",
      "&>div": {
        width: "auto"
      }
    }
  },
  time_avatar_aggregation: {
    width: "100%",
    textAlign: "center",
    fontSize: "12px",
    letterSpacing: "1px",
    fontWeight: "600"
  },
  toolprit_dashboard: {
    top: "-15px",
    padding: "5px 10px",
    fontSize: "12px"
  },
  tooltip_row_items: {
    marginTop: "3px"
  },

  modal_img_container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    "&>img": {
      width: "100%"
    },
    padding: "15px"
  }
}));
const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);
const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

function ButtonComponent(props) {
  const { onClick, loading } = props;

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={loading}
    >
      {loading ? (
        <CircularProgress
          style={{ height: "17px", width: "17px", zIndex: "5" }}
          color="primary"
        />
      ) : (
        <>Refresh</>
      )}
    </Button>
  );
}

function MyVerticallyCenteredModal(props) {
  const { classes, is_fetching_getting_prize } = props;
  const { show, link, text, bonus_name, d, fail } = props.prize_modal;
  const h_or = d
    ? Number(d.image_height) / Number(d.image_width) >=
      window.innerHeight / window.innerWidth
    : false;
  return (
    <Modal
      size="lg"
      show={show || is_fetching_getting_prize}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {is_fetching_getting_prize === true ? "You won..." : text}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={classes.modal_img_container}
        style={
          d && h_or
            ? {
                maxHeight: "100vh",
                width: "auto"
              }
            : {}
        }
      >
        {is_fetching_getting_prize === true ? (
          <div
            style={{
              height: "100px",
              fontWeight: "500",
              fontSize: "1rem",
              color: "#595d6e",
              width: "100%"
            }}
          >
            <LinearProgress />
            <br />
            <LinearProgress color="secondary" />
          </div>
        ) : (
          <img
            style={
              d && h_or
                ? {
                    height: "83vh",
                    width: "auto"
                  }
                : {}
            }
            alt="gif prize"
            src={link}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default function Dashboard() {
  const dispatch = useDispatch();
  const {
    issues,
    refresh_token,
    cloud_id,
    account_id,
    runningTask,
    subHeaderSelectedIds,
    dashboard_projects
  } = useSelector(state => ({
    brandColor: metronic.builder.selectors.getConfig(
      state,
      "colors.state.brand"
    ),
    dangerColor: metronic.builder.selectors.getConfig(
      state,
      "colors.state.danger"
    ),
    successColor: metronic.builder.selectors.getConfig(
      state,
      "colors.state.success"
    ),
    primaryColor: metronic.builder.selectors.getConfig(
      state,
      "colors.state.primary"
    ),
    issues:
      metronic.builder.selectors.getPageContentConfig(
        state,
        "pageContentConfig.dashboard.issues"
      ) || [],
    runningTask:
      metronic.builder.selectors.getPageContentConfig(
        state,
        "pageContentConfig.dashboard.runningTask"
      ) || {},
    subHeaderSelectedIds:
      metronic.builder.selectors.getPageContentConfig(
        state,
        "pageContentConfig.dashboard.subHeaderSelectedIds"
      ) || {},
    dashboard_projects:
      metronic.builder.selectors.getSubHeaderItems(state, "dashboard") || [],
    refresh_token: getStorage('jira_refresh_token'),
    cloud_id: metronic.auth.selectors.getAuthInfo(state, "auth.user.cloud_id"),
    account_id: metronic.auth.selectors.getAuthInfo(
      state,
      "auth.user.accountId"
    )
  }));
  let all_filter = true;
  for (let id_proj in subHeaderSelectedIds) {
    if (subHeaderSelectedIds[id_proj] === true) {
      all_filter = false;
      break;
    }
  }
  const classes = styles();
  const [expandedIssue, setExpandedIssue] = useState({
    key: Date.now(),
    id: null
  });
  const [comments, setComments] = useState([]);
  const [files, setFiles] = useState([]);
  const [isFetchingComments, setIsFetchingComments] = useState(false);
  const [totalComments, setTotalComments] = useState(0);
  const [isFetchingShowMoreComments, setIsFetchingShowMoreComments] = useState(
    false
  );
  const [is_fetching_req_info_comment, set_is_fetching_req_info] = useState({});
  const [isFetchingFiles, setIsFetchingFiles] = useState(false);

  const [runningTaskIndex, setRunningTaskIndex] = useState(-1);
  const [isFetchingRunningTask, setIsFetchingRunningTask] = useState(false);
  const [minutes, setMinutes] = useState("00 : 00 : 00");
  const [time_timer, set_time_timer] = useState(0);
  const [runTimer, setRunTimer] = useState(false);
  const [is_fetching_time_summary, set_is_fetching_time_summary] = useState(
    true
  );
  const [prize_modal, set_prize_modal] = useState({
    show: false,
    link: test_img,
    text: "You won respek. That's all!",
    bonus_name: "Ciuciu4",
    d: {
      image_width: "480",
      image_height: "552"
    },
    fail: 1
  });
  const [is_fetching_getting_prize, set_is_fetching_getting_prize] = useState(
    false
  );
  const [time_summary, set_time_summary] = useState({
    total: "00:00",
    tasks: []
  });
  const [commentBox, setCommentBox] = useState("");
  const [sendToJira, setSendToJira] = useState(false);
  const [selectWorkType, setSelectWorkType] = useState(0);
  const options_work_type = [
    "SELECT WORK TYPE",
    "DEV",
    "TESTING",
    "PROJECT MANAGEMENT",
    "BUG",
    "DESIGN",
    "CONTENT",
    "DOCUMENTATION",
    "CALL",
    "MEETING",
    "RESEARCH"
  ];

  const [isFetchingStatusChange, setIsFetchingStatusChange] = useState({});
  //migrations in fact
  const statuses = [
    { id: "81", name: "Ready" },
    { id: "41", name: "In Progress" },
    { id: "51", name: "In Testing" },
    { id: "61", name: "Done" },
    { id: "71", name: "Pushed Live" },
    { id: "31", name: "Require Info" }
  ];
  let statuses_map = {};
  for (let i = 0; i < statuses.length; i++) {
    statuses_map[statuses[i].name] = {
      index: i,
      id: statuses[i].id
    };
  }
  let statuses_selectct_options_perm = {
    Ready: 
      [statuses_map["Ready"].index, statuses_map["Require Info"].index],
    "In Progress": [
      statuses_map["In Progress"].index,
      statuses_map["Require Info"].index,
      statuses_map["In Testing"].index
    ]
  };
  const [
    is_fetching_refreshing_issues,
    set_is_fetching_refreshing_issues
  ] = useState(false);

  useEffect(() => {
    if (runningTask.isRunning === true) {
      onStartingTask(runningTask.task_id);
    }
  }, [runningTask.isRunning]);
  useEffect(() => {
    let projec_frec = {};
    let new_projects_header = [];
    let new_selected_ids = {};
    for (let i = 0; i < issues.length; i++) {
      if (
        objectPath.get(issues[i], "fields.project.id") &&
        (status_ids_[objectPath.get(issues[i], "fields.status.id")] === "Ready" ||
          status_ids_[objectPath.get(issues[i], "fields.status.id")] === "In Progress")
      ) {
        const project = objectPath.get(issues[i], "fields.project");
        if (!projec_frec[issues[i].fields.project.id]) {
          new_projects_header.push(project);
          new_selected_ids[project.id] = subHeaderSelectedIds[project.id];
          projec_frec[project.id] = 1;
        }
      }
    }
    dispatch(
      metronic.builder.actions.updateProjectsSubheader({
        new_projects_header,
        new_selected_ids
      })
    );
  }, [issues]);
  const convertDateToZeroSeconds = date_string => {
    let arr = date_string.split(" ");
    arr[1] = arr[1].split(":");
    arr[1][2] = "00";
    arr[1] = arr[1][0] + ":" + arr[1][1] + ":00";
    return arr[0] + " " + arr[1];
  };
  function setTimeSummary(result) {
    if (Array.isArray(result)) {
      let result = result;
      let time_summary_ = [];
      let total_time = 0;
      for (let i = 0; i < result.length; i++) {
        let date_end_ = convertDateToZeroSeconds(result[i].date_end);
        let date_start_ = convertDateToZeroSeconds(result[i].date_start);

        console.log(date_end_, date_start_);
        const task_time =
          (new Date(date_end_).getTime() - new Date(date_start_).getTime()) /
          1000;
        const hour = Math.floor(task_time / 3600);
        const min = Math.floor((task_time - hour * 3600) / 60);
        time_summary_.push({
          ...result[i],
          display_time: `${hour < 10 ? "0" + hour : hour}:${
            min < 10 ? "0" + min : min
          }`
        });
        total_time += task_time;
      }
      const hour = Math.floor(total_time / 3600);
      const min = Math.floor((total_time - hour * 3600) / 60);
      set_time_summary({
        total: `${hour < 10 ? "0" + hour : hour}:${min < 10 ? "0" + min : min}`,
        tasks: time_summary_
      });
    }
  }
  function check_for_prizes() {
    if (is_fetching_getting_prize === true) return;
    set_is_fetching_getting_prize(true);
    setTimeout(() => {
      axios
        .post(`get-premii.php`, { account_id })
        .then(({ data }) => {
          if (data) {
            console.log(data);
            set_prize_modal({ ...prize_modal, d: data.d, show: true, ...data });
          } else {
            console.log("Getting prizes: no data");
          }
          set_is_fetching_getting_prize(false);
        })
        .catch(err => {
          set_is_fetching_getting_prize(false);
          console.log(err, "failed to provide user with time summary data");
        });
    }, 1000);
  }
  const get_time_summary = () => {
    set_is_fetching_time_summary(true);
    setTimeout(() => {
      let account_id2 = "5e182c5fb540b70da83946cd";
      let account_id3 = "557058:c38de83e-6b96-4f30-b14e-4e32b0bc1fd7";
      account_id2 = account_id;
      axios
        .get(`get-time-summary.php?account_id=${account_id}`)
        .then(resp => {
          if (Array.isArray(resp.data.result)) {
            let result = resp.data.result;
            let time_summary_ = [];
            let total_time = 0;
            for (let i = 0; i < result.length; i++) {
              let date_end_ = convertDateToZeroSeconds(result[i].date_end);
              let date_start_ = convertDateToZeroSeconds(result[i].date_start);

              console.log(date_end_, date_start_);
              const task_time =
                (new Date(date_end_).getTime() -
                  new Date(date_start_).getTime()) /
                1000;
              const hour = Math.floor(task_time / 3600);
              const min = Math.floor((task_time - hour * 3600) / 60);
              time_summary_.push({
                ...result[i],
                display_time: `${hour < 10 ? "0" + hour : hour}:${
                  min < 10 ? "0" + min : min
                }`
              });
              total_time += task_time;
            }
            const hour = Math.floor(total_time / 3600);
            const min = Math.floor((total_time - hour * 3600) / 60);
            set_time_summary({
              total: `${hour < 10 ? "0" + hour : hour}:${
                min < 10 ? "0" + min : min
              }`,
              tasks: time_summary_
            });
          }
          set_is_fetching_time_summary(false);
        })
        .catch(err => {
          set_is_fetching_time_summary(false);
          console.log(err, "failed to provide user with time summary data");
        });
    }, 1000);
  };
  useEffect(() => {
    get_time_summary();
    if (account_id === "5e182c61cbf1830daa9d8bb7") {
      setTimeout(() => {
        setSnackBarMessage({
          message: "Sa traiesti, Irina.",
          open: true,
          duration: 6000,
          color: "success"
        });
        console.log("h");
      }, 1000);
    }
  }, [account_id]);
  function setSnackBarMessage(snackBarMessage) {
    dispatch(metronic.builder.actions.set_snack_bar({ snackBarMessage }));
  }
  const get_index_of_issue_with_task_id = task_id => {
    for (let i = 0; i < issues.length; i++) {
      if (issues[i].id === task_id) {
        return i;
      }
    }
    return -1;
  };
  const convertCommentJiraFormat = text => {
    let content = [];
    const paragraphs = text.split(/\n/);
    paragraphs.forEach(p_text => {
      content.push({
        type: "paragraph",
        content:
          p_text == ""
            ? []
            : new Array({
                type: "text",
                text: p_text
              })
      });
    });
    return {
      body: {
        type: "doc",
        version: 1,
        content: content
      }
    };
  };
  const validateCommentText = text => {
    return !text.replace(/\s/g, "").length == 0;
  };
  function setFetchingData(name, val) {
    switch (name) {
      case "comments":
        setIsFetchingComments(val);
        break;
      case "showMoreComments":
        setIsFetchingShowMoreComments(val);
        break;
    }
  }
  const getFetchData = name => {
    switch (name) {
      case "comments":
        return isFetchingComments;
      case "showMoreComments":
        return isFetchingShowMoreComments;
    }
  };
  function insertComment(new_comment, task_id) {
    if (expandedIssue.id !== task_id) return;

    let comment_text = {
      items: []
    };
    read_content(new_comment.body.content, comment_text);
    let comments_ = comments;
    comments_.push({
      author: new_comment.author,
      id: new_comment.id,
      items: comment_text.items,
      date: new Date(new_comment.updated)
    });
    setComments(comments_);
    setTotalComments(totalComments + 1);
  }
  function read_content(content, comment_text) {
    if (content === null || content === undefined) return;
    if (Array.isArray(content)) {
      for (let i = 0; i < content.length; i++) {
        read_content(content[i], comment_text);
      }
    } else {
      if (Array.isArray(content.content)) {
        if (content.content.length === 0) {
          read_content({ type: "text", text: <br></br> }, comment_text);
        }
        for (let i = 0; i < content.content.length; i++) {
          read_content(content.content[i], comment_text);
        }
      } else {
        comment_text.items.push(content);
      }
    }
  }
  function getComments(task_id, startAt, maxResults, expanded) {
    const fetch_n = totalComments === 0 ? "comments" : "showMoreComments";
    if (getFetchData(fetch_n) === true) return;
    setFetchingData(fetch_n, true);
    let comments_;
    if (expanded === true) {
      comments_ = [];
    } else {
      comments_ = comments.reverse();
    }
    returnNewToken(refresh_token)
      .then(access_token => {
        if (access_token != null) {
          const payload = {
            task_id: task_id,
            cloud_id: cloud_id,
            startAt: startAt,
            maxResults: maxResults,
            access_token: access_token
          };
          axios
            .post("get-comments.php", payload)
            .then(response => {
              console.log(response);

              if (
                response.data.total &&
                Number.isInteger(response.data.total) &&
                response.data.total > 0
              ) {
                setTotalComments(Number(response.data.total));
                let new_comments = response.data.comments;
                if (Array.isArray(new_comments)) {
                  for (let i = 0; i < new_comments.length; i++) {
                    let comment_text = {
                      items: []
                    };
                    read_content(new_comments[i].body.content, comment_text);
                    comments_.push({
                      author: new_comments[i].author,
                      id: new_comments[i].id,
                      items: comment_text.items,
                      date: new Date(new_comments[i].updated)
                    });
                  }
                }
              }
              setComments(comments_.reverse());
              setFetchingData(fetch_n, false);
            })
            .catch(err => {
              setFetchingData(fetch_n, false);
              setComments(comments_.reverse());
              console.log(err);
            });
        } else {
          setFetchingData(fetch_n, false);
          setComments(comments_.reverse());
          console.log("ERROR: empty access token");
        }
      })
      .catch(er => {
        setFetchingData(fetch_n, false);
        setComments(comments_.reverse());
        console.log(er, "ERROR: getting access token failed");
      });
  }
  function getAttachments(task_id) {
    setIsFetchingFiles(true);
    returnNewToken(refresh_token)
      .then(access_token => {
        if (access_token != null) {
          axios
            .get(
              `get-task.php?cloud_id=${cloud_id}&task_id=${task_id}&access_token=${access_token}`
            )
            .then(response => {
              const task = response.data;
              if (
                objectPath(task, "fields.attachment") &&
                Array.isArray(task.fields.attachment)
              ) {
                setFiles(task.fields.attachment);
              }
              setIsFetchingFiles(false);
            })
            .catch(err => {
              setIsFetchingFiles(false);
              console.log(err);
            });
        } else {
          setIsFetchingFiles(true);
          setExpandedIssue({ ...expandedIssue, id: null });
          console.log("ERROR: null access token");
        }
      })
      .catch(er => {
        setIsFetchingFiles(false);
        setExpandedIssue({ ...expandedIssue, id: null });
        console.log(er);
      });
  }
  function expandIssue(task_id) {
    setTotalComments(0);
    setFiles([]);
    setComments([]);
    getComments(task_id, 0, 8, true);
    getAttachments(task_id);
  }
  function onPausingTask() {
    if (isFetchingRunningTask === true) return;
    setIsFetchingRunningTask(true);
    const task_id = runningTask.task_id;
    returnNewToken(refresh_token)
      .then(access_token => {
        const payload = {
          task_id: task_id,
          account_id: account_id,
          work_type: selectWorkType,
          comment: commentBox,
          comment_jira:
            sendToJira === true ? convertCommentJiraFormat(commentBox) : "",
          add_to_jira: sendToJira === true ? 1 : 0
        };
        axios
          .post(
            `pause.php?cloud_id=${cloud_id}&access_token=${access_token}`,
            payload
          )
          .then(({ data }) => {
            if (data === 1) {
              dispatch(metronic.builder.actions.pauseRunningTask(0));
              //refresh comment if was added the expanded task
              const { add_to_jira, task_id } = payload;
              if (add_to_jira && expandedIssue.id === task_id) {
                expandIssue(task_id);
              }
              setSendToJira(false);
              setCommentBox("");
              setSelectWorkType(0);
              get_time_summary();
            } else {
              console.log("EROR: pausing task: Query failed.");
              if (data === 2) {
                setSnackBarMessage({
                  message: "Task was already stopped.",
                  open: true,
                  duration: 6000,
                  color: "error"
                });
                setSendToJira(false);
                setCommentBox("");
                setSelectWorkType(0);
                dispatch(metronic.builder.actions.pauseRunningTask(0));
              } else {
                onStartingTask(task_id);
              }
            }
            setIsFetchingRunningTask(false);
          })
          .catch(er => {
            console.log(er);
            setIsFetchingRunningTask(false);
            onStartingTask(task_id);
          });
      })
      .catch(er => {
        console.log("EROR: pausing task: GENERATING TOKEN FAILED.");
        setIsFetchingRunningTask(false);

        onStartingTask(task_id);
      });
  }
  //on button press => create a record into table
  function startTaskButton(task_id) {
    if (isFetchingRunningTask === true) return;
    setIsFetchingRunningTask(true);

    returnNewToken(refresh_token)
      .then(access_token => {
        if (access_token != null) {
          axios
            .get(`open-task_test.php?account_id=${account_id}`)
            .then(({ data }) => {
              //check if OTHER task than selected is running
              if (
                data.task_id &&
                data.task_id != null &&
                data.task_id !== task_id
              ) {
                const alr_running_task_id = data.task_id;
                const alr_running_task_time = data.time_runned || 0;
                console.log(
                  "CAN'T RUN SELECTED TASK BECAUSE SOME OTHER TASK IS ALREADY RUNNING at *ID= " +
                    alr_running_task_id
                );
                setSnackBarMessage({
                  message: "There's another task already running!",
                  color: "error",
                  duration: 6000,
                  open: true
                });
                //check if the already running task exists or was added after page was loaded
                const exists = issues.find(is => is.id === alr_running_task_id);
                if (exists == undefined) {
                  console.log(
                    "TASK DOES NOT EXISTS IN CURRENT LIST... wainting fetching the new task..."
                  );

                  axios
                    .get(
                      `get-task.php?cloud_id=${cloud_id}&task_id=${alr_running_task_id}&access_token=${access_token}`
                    )
                    .then(response => {
                      const new_task = response.data;
                      if (new_task.id != null) {
                        dispatch(
                          metronic.builder.actions.addNewIssue(new_task)
                        );
                        dispatch(
                          metronic.builder.actions.startTask({
                            isRunning: true,
                            task_id: alr_running_task_id,
                            time: alr_running_task_time
                          })
                        );
                        isFetchingRunningTask(false);
                      } else {
                        setIsFetchingRunningTask(false);
                        console.log("Getting the NEW task data failed.");
                      }
                    })
                    .catch(err => {
                      setIsFetchingRunningTask(false);
                      console.log(
                        err,
                        "Erorr while: Getting running task data failed."
                      );
                    });
                } else {
                  //we need to update frontend data acording to the issues from sever which were change in other tab maybe
                  if (
                    status_ids_[objectPath.get(exists, "fields.status.id")] === "Ready"
                  ) {
                    dispatch(
                      metronic.builder.actions.changeIssueStatus({
                        alr_running_task_id,
                        new_status_name: "In Progress"
                      })
                    );
                  }
                  dispatch(
                    metronic.builder.actions.startTask({
                      isRunning: true,
                      task_id: alr_running_task_id,
                      time: alr_running_task_time
                    })
                  );
                  setIsFetchingRunningTask(false);
                }
              } else {
                dispatch(
                  metronic.builder.actions.startTask({
                    isRunning: true,
                    task_id: task_id,
                    time: 0
                  })
                );
                setIsFetchingRunningTask(false);
              }
            })
            .catch(er => {
              setIsFetchingRunningTask(false);
              console.log(er, "error: Task running check failed.");
            });
        } else {
          setIsFetchingRunningTask(false);
          console.log("error: null access token");
        }
      })
      .catch(er => {
        setIsFetchingRunningTask(false);
        console.log(er, "error: getting access token.");
      });
  }
  //gets the time of already running task
  function onStartingTask(task_id) {
    let index_issue = get_index_of_issue_with_task_id(task_id);
    if (index_issue === -1) {
      console.log("issues doesn't exist in array of isssues somehow");
      return;
    }
    setIsFetchingRunningTask(true);
    returnNewToken(refresh_token)
      .then(access_token => {
        if (access_token) {
          let payload = {
            project_category_id: objectPath.get(
              issues[index_issue],
              "fields.project.projectCategory.id"
            ),
            project_category_name: objectPath.get(
              issues[index_issue],
              "fields.project.projectCategory.name"
            ),
            project_id: objectPath.get(
              issues[index_issue],
              "fields.project.id"
            ),
            project_name: objectPath.get(
              issues[index_issue],
              "fields.project.name"
            ),
            task_id: task_id,
            task_name: objectPath.get(issues[index_issue], "fields.summary"),
            account_id: account_id,
            task_status: status_ids_[objectPath.get(
              issues[index_issue],
              "fields.status.id"
            )],
            access_token: access_token,
            cloud_id: cloud_id
          };
          axios
            .post(`${axios.defaults.baseURL}/start.php`, payload)
            .then(({ data }) => {
              if (Number.isInteger(data)) {
                dispatch(
                  metronic.builder.actions.startTask({
                    time: data,
                    isRunning: true,
                    task_id: task_id
                  })
                );
                if (
                  status_ids_[objectPath.get(issues[index_issue], "fields.status.id")] ===
                  "Ready"
                ) {
                  dispatch(
                    metronic.builder.actions.changeIssueStatus({
                      task_id,
                      new_status_name: "In Progress"
                    })
                  );
                }
                setRunningTaskIndex(index_issue);
                setRunTimer(true);
                setIsFetchingRunningTask(false);
              } else {
                setIsFetchingRunningTask(false);
                console.log("ERROR: getting task's time !");
                setSnackBarMessage({
                  message: "Error while fetching task working duration.",
                  color: "error",
                  duration: 6000,
                  open: true
                });
              }
            })
            .catch(er => {
              setIsFetchingRunningTask(false);
              console.log(er, "ERROR while starting task");
            });
        } else {
          setIsFetchingRunningTask(false);
          console.log("ERROR: starting task: access token empty");
        }
      })
      .catch(er => {
        setIsFetchingRunningTask(false);
        console.log("ERROR: starting task: failed access token loading");
      });
  }
  function changeStatusIssue(task_id, new_status_name) {
    if (isFetchingStatusChange[task_id] === true) return;
    setIsFetchingStatusChange({
      ...isFetchingStatusChange,
      [task_id]: true
    });
    returnNewToken(refresh_token)
      .then(access_token => {
        if (access_token) {
          const payload = {
            task_id: task_id,
            account_id: account_id,
            status_id: statuses_map[new_status_name].id,
            cloud_id: cloud_id,
            access_token: access_token
          };
          axios
            .post(`update-status.php`, payload)
            .then(({ data }) => {
              if (data === 1) {
                console.log("hei");
                setIsFetchingStatusChange({
                  ...isFetchingStatusChange,
                  [task_id]: false
                });
                if (
                  new_status_name === "In Testing" ||
                  new_status_name === "Done"
                ) {
                  check_for_prizes();
                }
                dispatch(metronic.builder.actions.removeIssue(task_id));
              } else {
                console.log("EROR: status changing task: server response.");
                setIsFetchingStatusChange({
                  ...isFetchingStatusChange,
                  [task_id]: false
                });
              }
            })
            .catch(er => {
              setIsFetchingStatusChange({
                ...isFetchingStatusChange,
                [task_id]: false
              });
              console.log(er);
            });
        } else {
          setIsFetchingStatusChange({
            ...isFetchingStatusChange,
            [task_id]: false
          });
          console.log("changing status ERROR -> getting access token failed..");
        }
      })
      .catch(er => {
        setIsFetchingStatusChange({
          ...isFetchingStatusChange,
          [task_id]: false
        });
        console.log("EROR: pausing task: GENERATING TOKEN FAILED.");
      });
  }
  function require_info_issue(task_id, req_comment) {
    if (is_fetching_req_info_comment[task_id] === true) return;
    set_is_fetching_req_info({
      ...is_fetching_req_info_comment,
      [task_id]: true
    });
    returnNewToken(refresh_token)
      .then(access_token => {
        if (access_token) {
          const payload = {
            access_token: access_token,
            cloud_id: cloud_id,
            comment: convertCommentJiraFormat(req_comment),
            task_id: task_id
          };
          axios.post("add-comment.php", payload).then(({ data }) => {
            if (data.id) {
              changeStatusIssue(task_id, "Require Info");
              set_is_fetching_req_info({
                ...is_fetching_req_info_comment,
                [task_id]: false
              });
            } else {
              set_is_fetching_req_info({
                ...is_fetching_req_info_comment,
                [task_id]: false
              });
              console.log("Server: Comment could not be added.");
            }
          });
        } else {
          set_is_fetching_req_info({
            ...is_fetching_req_info_comment,
            [task_id]: false
          });
          console.log("Erro: empty access token.");
        }
      })
      .catch(err => {
        set_is_fetching_req_info({
          ...is_fetching_req_info_comment,
          [task_id]: false
        });
        console.log(err, "EROR: getting access token.");
      });
  }
  function refreshIssues() {
    // check_for_prizes();
    set_is_fetching_refreshing_issues(true);
    get_time_summary();
    returnNewToken(refresh_token)
      .then(access_token => {
        axios
          .get(
            `get-tasks.php?cloud_id=${cloud_id}&access_token=${access_token}`
          )
          .then(response => {
            if (response.data.issues && Array.isArray(response.data.issues)) {
              let new_issues = response.data.issues;
              dispatch(
                metronic.builder.actions.setAllIssues({
                  new_issues
                })
              );
              set_is_fetching_refreshing_issues(false);
            } else {
              set_is_fetching_refreshing_issues(false);
              console.log("No issue returned.");
            }
          })
          .catch(err => {
            set_is_fetching_refreshing_issues(false);
            console.log(err);
          });
      })
      .catch(er => {
        console.log(er);
        set_is_fetching_refreshing_issues(false);
      });
  }
  return (
    <>
      <div className="row dashboard_row__">
        <div className="dialog_box_dashboard">
          <Dialog
            style={
              window.innerWidth > 700
                ? {
                    padding: "25px 35px 15px 35px"
                  }
                : { margin: 0 }
            }
            aria-labelledby="customized-dialog-title"
            open={
              runningTask.task_id &&
              runningTask.isRunning === true &&
              runningTaskIndex > -1
                ? true
                : false
            }
            maxWidth={"md"}
          >
            <MuiDialogTitle disableTypography className={classes.root}>
              <Typography variant="h6" className={classes.title}>
                {objectPath.get(issues[runningTaskIndex], "fields.summary") ||
                  "Sumay undefined"}
              </Typography>
              <div className={classes.divider}>
                <Divider variant="fullWidth" />
              </div>
            </MuiDialogTitle>
            <DialogContent className={classes.dialocContent}>
              <Typography gutterBottom>
                {runTimer === true ? (
                  <Timer
                    key={issues[runningTaskIndex].id + "_" + runningTask.time}
                    time={runningTask.time}
                    getTimePassed={(time, minutes) => {
                      setMinutes(minutes);
                      set_time_timer(time);
                    }}
                  />
                ) : (
                  <> {minutes} </>
                )}
              </Typography>
              <TextField
                id="outlined-full-width"
                label="Comment"
                helperText={"Left characters: " + (255 - commentBox.length)}
                fullWidth
                margin="normal"
                variant="outlined"
                multiline={true}
                rowsMax={4}
                rows={4}
                onChange={e => {
                  const value = e.target.value;
                  if (value.length < 255) {
                    setCommentBox(value);
                  }
                }}
              />
              <div className={classes.action_inputs_cont}>
                <FormControlLabel
                  value={sendToJira}
                  control={<Checkbox color="primary" />}
                  label="Add to Jira"
                  labelPlacement="end"
                  onChange={e => {
                    setSendToJira(!sendToJira);
                  }}
                />
                <div className={classes.select}>
                  <DropdownButton
                    className="button_dropdown_"
                    alignRight
                    title={options_work_type[selectWorkType]}
                    id="dropdown-menu-align-right"
                    stylle={{ right: "0" }}
                  >
                    {options_work_type.map((item, index) => (
                      <Dropdown.Item
                        style={
                          index === 0 && selectWorkType > 0
                            ? {
                                display: "none"
                              }
                            : {}
                        }
                        className={classes.dropdown_button_child}
                        key={index}
                        onClick={() => {
                          setSelectWorkType(index);
                        }}
                      >
                        {item}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                disabled={
                  !validateCommentText(commentBox) ||
                  selectWorkType < 1 ||
                  isFetchingRunningTask === true
                    ? true
                    : false || commentBox.length < 1
                }
                onClick={() => {
                  onPausingTask();
                }}
                style={{ height: "40px", width: "100%" }}
              >
                {isFetchingRunningTask === true ? (
                  <CircularProgress
                    style={{ height: "30px", width: "30px", zIndex: "5" }}
                    color="secondary"
                  />
                ) : (
                  <> PAUSE TASK </>
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <MyVerticallyCenteredModal
            classes={classes}
            prize_modal={prize_modal}
            is_fetching_getting_prize={is_fetching_getting_prize}
            onHide={() => {
              set_prize_modal({
                ...prize_modal,
                show: false,
                fail: 1
              });
            }}
          />
        </div>

        <div className="col-xl-4 dashboard_col1__">
          <div className="kt-portlet kt-portlet--height-fluid to_do_list_container">
            <div
              className="kt-portlet__head"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">Todo List</h3>
              </div>
              <div className="kt-portlet__head-label">
                <div className={classes.refresh_tasks_button}>
                  <ButtonComponent
                    onClick={refreshIssues}
                    loading={is_fetching_refreshing_issues}
                  />
                </div>
              </div>
              {/* <PortletHeaderDropdown /> */}
            </div>
            <div className="kt-portlet__body">
              <div className="kt-widget4">
                {issues.length > 0 ? (
                  issues.map((issue, index) => {
                    if (
                      (status_ids_[objectPath.get(issue, "fields.status.id")] ===
                        "Ready" ||
                        status_ids_[objectPath.get(issue, "fields.status.id")] ===
                          "In Progress") &&
                      (all_filter === true ||
                        subHeaderSelectedIds[
                          objectPath.get(issue, "fields.project.id")
                        ] === true)
                    ) {
                      return (
                        <IssuesItem
                          expand={
                            expandedIssue.id != null &&
                            expandedIssue.id === issue.id
                          }
                          read_content={read_content}
                          key={issue.id}
                          index={index}
                          issue={issue}
                          onExpandIssue={() => {
                            setExpandedIssue({ key: Date.now(), id: issue.id });
                            expandIssue(issue.id);
                          }}
                          onCollapseIssue={() => {
                            setExpandedIssue({ ...expandedIssue, id: null });
                            setComments([]);
                            setFiles([]);
                            setTotalComments(0);
                          }}
                          startTaskButton={startTaskButton}
                          isFetchingRunningTask={isFetchingRunningTask}
                          refresh_token={refresh_token}
                          cloud_id={cloud_id}
                          comments={
                            expandedIssue.id != null &&
                            expandedIssue.id === issue.id
                              ? comments || []
                              : []
                          }
                          totalComments={totalComments}
                          showMoreComments={getComments}
                          insertComment={insertComment}
                          convertCommentJiraFormat={convertCommentJiraFormat}
                          isFetchingComments={isFetchingComments}
                          isFetchingShowMoreComments={
                            isFetchingShowMoreComments
                          }
                          isFetchingFiles={isFetchingFiles}
                          files={files || []}
                          require_info_issue={req_comment => {
                            require_info_issue(issue.id, req_comment);
                          }}
                          isFetchingStatusChange={
                            isFetchingStatusChange[issue.id]
                          }
                          is_fetching_req_info_comment={
                            is_fetching_req_info_comment[issue.id]
                          }
                          custom_statuses_select={
                            objectPath.get(
                              issue,
                              "fields.creator.accountId"
                            ) === account_id &&
                            status_ids_[objectPath.get(issue, "fields.status.id")] !==
                              "Ready"
                              ? [
                                  ...statuses_selectct_options_perm[
                                    status_ids_[issue.fields.status.id]
                                  ],
                                  statuses_map["Done"].index
                                ].map(ind => {
                                  return statuses[ind];
                                })
                              : statuses_selectct_options_perm[
                                  status_ids_[issue.fields.status.id]
                                ].map(ind => {
                                  return statuses[ind];
                                })
                          }
                          onChangeStatus={new_status_name => {
                            changeStatusIssue(issue.id, new_status_name);
                          }}
                          validateCommentText={validateCommentText}
                        />
                      );
                    }
                  })
                ) : (
                  <div
                    style={{
                      height: "100px",
                      fontWeight: "500",
                      fontSize: "1rem",
                      color: "#595d6e"
                    }}
                  >
                    No task found.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 dashboard_col2___">
          <div className="kt-portlet kt-portlet--height-fluid">
            <div
              className="kt-portlet__head"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">Today</h3>
              </div>
              <div className="kt-portlet__head-label">
                <div className={classes.refresh_tasks_button}>
                  <div>
                    <div>{time_summary.total}</div>
                  </div>
                </div>
              </div>
              {/* <PortletHeaderDropdown /> */}
            </div>
            <div className="kt-portlet__body" style={{ minHeight: "116px" }}>
              <div className="kt-widget4" style={{ position: "relative" }}>
                {is_fetching_time_summary === true ? (
                  <div
                    style={{
                      height: "100px",
                      marginTop: time_summary.tasks.length > 0 ? "17px" : "",
                      fontWeight: "500",
                      fontSize: "1rem",
                      color: "#595d6e",
                      position: "absolute",
                      width: "100%"
                    }}
                  >
                    <LinearProgress />
                    <br />
                    <LinearProgress color="secondary" />
                  </div>
                ) : time_summary.tasks.length === 0 ? (
                  <div
                    style={{
                      height: "100px",
                      fontWeight: "500",
                      fontSize: "1rem",
                      color: "#595d6e"
                    }}
                  >
                    {"No task runned today."}
                  </div>
                ) : (
                  ""
                )}
                {time_summary.tasks.map(item => {
                  return (
                    <div
                      className="kt-widget_container_"
                      style={{
                        visibility:
                          is_fetching_time_summary === true ? "hidden" : ""
                      }}
                    >
                      <div className="kt-widget4__item kt-widget__dashboard">
                        <div className="kt-widget4__pic kt-widget4__pic--pic">
                          <Avatar
                            alt="NatProject coordonatoracha"
                            style={{
                              width: "45px",
                              height: "45px"
                            }}
                          >
                            <div className={classes.time_avatar_aggregation}>
                              {item.display_time}
                            </div>
                          </Avatar>
                        </div>
                        <div className="kt-widget4__info ">
                          <div className="kt-widget4__username">
                            {item.task_name}
                          </div>
                          <div className="kt-widget4__text ">
                            <Moment format="HH:mm" date={item.date_start} />
                            {"  -  "}
                            <Moment format="HH:mm" date={item.date_end} />
                          </div>
                        </div>
                        <div className="kt-widget4_action_btns_container">
                          <div className="kt-section__content">
                            <div
                              className="kt-widget4__text "
                              style={{ textAlign: "right" }}
                            >
                              {item.project_name}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
