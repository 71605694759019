/* eslint-disable no-restricted-imports */
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Chip, Avatar, Paper } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import { useSubHeaderDispatch } from "../LayoutContext";
import HorizontalScroll from "react-scroll-horizontal";
import objectPath from "object-path";
import { useDispatch, useSelector } from "react-redux";
import * as builder from "../../ducks/builder";
import HeaderScrollHorizontal from './HeaderScrollHorizontal';

const useStyles1 = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing(1)
  }
}));
const useStyles2 = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing(1),
    fontSize: "14px"
  },
  title: {
    margin: "0 2px"
  }
}));
const useStyles3 = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: theme.spacing(0.5)
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}));
const useStyles4 = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing(1)
  }
}));
const useStyles5 = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing(1)
  }
}));

export default function HeaderRightSubMenuList(props) {
  //some backup styles
  const classes2 = useStyles2();
  const { width, pageName } = props;

  const { subHeaderSelectedIds, items } = useSelector(state => ({
    subHeaderSelectedIds:
      builder.selectors.getPageContentConfig(
        state,
        `pageContentConfig.${pageName}.subHeaderSelectedIds`
      ) || {},
    items:
      builder.selectors.getSubHeaderItems(
        state,
        'dashboard'
      ) || []
  }));
  const dispatch = useDispatch();
  function selectedItemSubHeader(id, val) {
    dispatch(builder.actions.selectedItemSubHeader({ id, val, pageName }));
  }
  function imageExists(image_url) {
    var http = new XMLHttpRequest();

    http.open("HEAD", image_url, false);
    http.send();
    return http.status != 404;
  }
  const listChildren = items.map((item, index) => {
    return (
      <Chip
        key={index}
        avatar={
          1 === 2 && objectPath.get(item, "avatarUrls.48x48") ? (
            <Avatar
              alt="Project coordonator"
              src={objectPath.get(item, "avatarUrls.48x48")}
            />
          ) : (
            <Avatar alt="NatProject coordonatoracha">
              {item.name
                .split(" ")
                .map(n => {
                  return n.slice(0, 1).toUpperCase();
                })
                .join(" ")}
            </Avatar>
          )
        }
        className={classes2.chip}
        color={
          subHeaderSelectedIds == null || subHeaderSelectedIds[item.id]
            ? "secondary"
            : "default"
        }
        variant="outlined"
        deleteIcon={<DoneIcon />}
        label={<div className={classes2.title}>{item.name}</div>}
        clickable
        onClick={() => {
          const val = subHeaderSelectedIds[item.id] == null ? true : null;
          selectedItemSubHeader(item.id, val);
        }}
        // onDelete={
        //   () => {
        //     const submenu_items_ = items.splice(index, 1);
        //     const additional = { items: submenu_items_ };
        //     dispatch({ type: 'UPDATE_SUBHEADER_ITEMS', payload: submenu_items_ });
        //   }}
      />
    );
  });
  const arr = [0];
  return (    
  <HeaderScrollHorizontal>
    {listChildren}
  </HeaderScrollHorizontal>
  );
}
