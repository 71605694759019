import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { login } from "../../crud/auth.crud";
import { returnNewToken} from '../../../_metronic/utils/utils';
import axios from "axios";

let get_data = {};

const checkLinkParameters = () => {
  let link = window.location.href;
  link = link.split("?")[1];
  if (link) {
    const params = link.split("&");
    for (let i = 0; i < params.length; i++) {
      const prop = params[i].split("=");
      get_data[prop[0]] = prop[1];
    }
  }
};
checkLinkParameters();

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
    width: "100%"
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem", width: "100%" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem", width: "100%" });
  };
  useEffect(() => {
    if (get_data.cloud_id && get_data.refresh_token) {
      enableLoading();
      console.log({get_data})
      returnNewToken(get_data.refresh_token).then(
        (access_token) => {
          login({access_token:access_token, cloud_id:get_data.cloud_id})
          .then((resp) => {
            disableLoading();
            if (resp.data) {
              const data = {...resp.data, refresh_token:get_data.refresh_token, cloud_id:get_data.cloud_id }
              get_data = [];
              props.login(data);
            } else {
              disableLoading();
            }
          })
          .catch((er) => {
            console.log(er);
            disableLoading();
          });
        }
      )
      .catch((er) => {
        console.log(er);
        disableLoading();
      });

    }
  }, [get_data]);

  return (
    <>
      <div className="kt-login__head">
        {/* <span className="kt-login__signup-label">
          Don't have an account yet?
        </span>
        &nbsp;&nbsp;
        <Link to="/auth/registration" className="kt-link kt-login__signup-link">
          Sign Up!
        </Link> */}
      </div>

      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
          </div>

          <Formik
            initialValues={{
              email: "admin@demo.com",
              password: "demo"
            }}
            validate={values => {
              const errors = {};

              if (!values.email) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_FIELD"
                });
              }

              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              window.location.href =
                `${axios.defaults.baseURL}/jira.php`;
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="kt-login__actions">
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isSubmitting}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                    style={loadingButtonStyle}
                  >
                    Sign In
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
