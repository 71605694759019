import React, {useState} from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import { withRouter } from "react-router-dom";

import Header from "./header/Header";
import SubHeader from "./sub-header/SubHeader";
import HeaderMobile from "./header/HeaderMobile";
import AsideLeft from "./aside/AsideLeft";
import Footer from "./footer/Footer";
import ScrollTop from "../../app/partials/layout/ScrollTop";
import LayoutInitializer from "./LayoutInitializer";
import QuickPanel from "../../app/partials/layout/QuickPanel";
import KtContent from "./KtContent";

//initialize state helpers
import HTMLClassService from "./HTMLClassService";
import LayoutConfig from "./LayoutConfig";
import MenuConfig from "./MenuConfig";

import SnackBar from '../../app/pages/home/reusableFields/SnackBar';

import("./assets/Base.scss");



const htmlClassService = new HTMLClassService();

function Layout({
    children,
    asideDisplay,
    subheaderDisplay,
    selfLayout,
    layoutConfig,
    contentContainerClasses,
    LoadingPage,
    location
}) {

    const [menuCong, setMenuConfig] = useState(MenuConfig);
    htmlClassService.setConfig(layoutConfig);
    // scroll to top after location changes
    // window.scrollTo(0, 0);

    const contentCssClasses = htmlClassService.classes.content.join(" ");

    return selfLayout !== "blank" ? (

        <LayoutInitializer
            menuConfig={menuCong}
            layoutConfig={LayoutConfig}
            htmlClassService={htmlClassService}
            LoadingPage={LoadingPage}
            location={location}
        >

            {/* <!-- begin:: Header Mobile --> */}
            <HeaderMobile />
            {/* <!-- end:: Header Mobile --> */}

            <div className="kt-grid kt-grid--hor kt-grid--root">
                {/* <!-- begin::Body --> */}
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                    {/* <!-- begin:: Aside Left --> */}
                    {   
                        asideDisplay 
                        && 
                        (
                            <>
                                <AsideLeft />
                            </>
                        )
                    }
                    {/* <!-- end:: Aside Left --> */}
                    <div
                        className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
                        id="kt_wrapper"
                    >
                        {/* <!-- begin:: Header READY --> */}

                        <Header />
                        {/* <!-- end:: Header --> */}

                        {/* <!-- begin:: Content --> */}
                        <div
                            id="kt_content"
                            className={`kt-content ${contentCssClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
                        >
                            {/* <!-- begin:: Content Head --> */}
                            {subheaderDisplay && <SubHeader />}
                            {/* <!-- end:: Content Head --> */}

                            {/* <!-- begin:: Content Body --> */}
                            {/* TODO: add class to animate  kt-grid--animateContent-finished */}
                            <KtContent>{children}</KtContent>
                            {/*<!-- end:: Content Body -->*/}
                        </div>
                        {/* <!-- end:: Content --> */}
                        <Footer />
                    </div>
                </div>
                {/* <!-- end:: Body --> */}
            </div>
            <QuickPanel />
            <ScrollTop />
            <SnackBar/>
        </LayoutInitializer>
    ) : (
        // BLANK LAYOUT
        <div className="kt-grid kt-grid--ver kt-grid--root">
            <KtContent>{children}</KtContent>
        </div>
    );
}

const mapStateToProps = ({ builder: { layoutConfig, LoadingData }  }) => ({
    layoutConfig,
    selfLayout: objectPath.get(layoutConfig, "self.layout"),
    asideDisplay: objectPath.get(layoutConfig, "aside.self.display"),
    subheaderDisplay: objectPath.get(layoutConfig, "subheader.display"),
    desktopHeaderDisplay: objectPath.get(
        layoutConfig,
        "header.self.fixed.desktop"
    ),
    LoadingPage: LoadingData.LoadingPage,
    contentContainerClasses: ""
    // contentContainerClasses: builder.selectors.getClasses(store, {
    //   path: "content_container",
    //   toString: true
    // })
});

export default withRouter(connect(mapStateToProps)(Layout));
