import React, { useMemo, useState } from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import objectPath from "object-path";
import { Dropdown, DropdownButton, Alert } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { Divider, Button, Typography, Dialog } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Avatar from "@material-ui/core/Avatar";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";

import { Spinner } from "react-bootstrap";
import ButtonB from "react-bootstrap/Button";

import axios from "axios";
import { returnNewToken } from "../../../_metronic/utils/utils";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { metronic } from "../../../_metronic";
import IssuesItem from "./reusableFields/IssuesItem";
import Timer from "./reusableFields/Timer";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";

import PropTypes, { array } from "prop-types";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import { amber, blueGrey, green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

const styles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    width: "652px",
    padding: "10px 35px 0 35px",
  },
  dialocContent: {
    width: "100%",
    padding: "25px 35px 15px 35px!important",
  },
  divider: {
    width: "100%",
    height: "16px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    marginTop: "5px",
    marginBottom: "8px",
  },
  action_inputs_cont: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "25px 5px",
  },
  select: {
    // width: "200px",
  },
  dropdown_button: {
    width: "100%",
  },
  dropdown_button_child: {
    width: "100%",
  },
  undeInputText: {
    fontSize: "0.85rem",
    margin: "8px 4px 0",
  },
  refresh_tasks_button: {
    width: "100px",
    display: "flex",
    justifyContent: "flex-end",
    height: "100%",
    alignItems: "center",
    "spinner-border": {
      width: "15px",
      height: "15px",
      border: "0.15em solid currentColor",
    },
    "&>button": {
      width: "80px",
      height: "30px",
    },
    "&>div": {
      fontSize: "18px",
      fontWeight: "bold",
      width: "auto",
      height: "30px",
      margin: "0",
      padding: "5px",
      textAlign: "center!important",
      display: "flex",
      justifyContent: "center",
      alignItems: "fle-end",
      "&>div": {
        width: "auto",
      },
    },
  },
  time_avatar_aggregation: {
    width: "100%",
    textAlign: "center",
    fontSize: "12px",
    letterSpacing: "1px",
    fontWeight: "600",
  },
  toolprit_dashboard: {
    top: "-15px",
    padding: "5px 10px",
    fontSize: "12px",
  },
  tooltip_row_items: {
    marginTop: "3px",
  },
  modal_img_container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    "&>img": {
      width: "100%",
    },
    padding: "15px",
  },
}));

function createData(angajat, datastart, datasfarsit, status, extra = {}) {
  return { angajat, datastart, datasfarsit, status, extra };
}

const rows = [
  createData("Ana-Maria", "03.03.2022", "10.03.2022", "In asteptare"),
  createData("Ion", "15.03.2022", "25.03.2022", " Aprobat"),
];

function ButtonComponent(props) {
  const { onClick } = props;

  return (
    <div
      style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}
    >
      <Button variant="contained" color="primary" onClick={onClick}>
        Add request
      </Button>
    </div>
  );
}

var addRequestValue = true;

function SubmitButton(props) {
  const { onClick } = props;
  return (
    <Button
      className="submit_request_button"
      variant="contained"
      style={{ marginLeft: "1px" }}
      color="primary"
      onClick={onClick}
    >
      Submit
    </Button>
  );
}

function YesButton(props) {
  const { onClick } = props;
  return (
    <Button
      className="submit_request_button"
      variant="contained"
      style={{ marginLeft: "1px" }}
      color="primary"
      onClick={onClick}
    >
      Yes
    </Button>
  );
}
function NoButton(props) {
  const { onClick } = props;
  return (
    <Button
      className="submit_request_button"
      variant="contained"
      style={{ marginLeft: "1px" }}
      color="primary"
      onClick={onClick}
    >
      No
    </Button>
  );
}

function CloseRequestButton(props) {
  const { onClick } = props;
  return (
    <div
      style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}
    >
      <Button
        variant="contained"
        //color="primary"
        onClick={onClick}
      >
        Close
      </Button>
    </div>
  );
}

//....DIALOG

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function Concedii() {
  //Date
  const today = new Date();
  const tomorrow = new Date();

  tomorrow.setDate(tomorrow.getDate() + 1);

  const [values, setValues] = useState([today, tomorrow]);

  const [concedii, setConcedii] = useState([
    createData("Ana-Maria", "2022/03/10", "2022/03/20", "In asteptare", {
      comentariu: "test 123",
      dateSelectate: ["123", "456"],
    }),
    createData("Ion", "2022/02/10", "2022/02/12", " Aprobat", {
      comentariu: "test 123",
      dateSelectate: ["123", "456"],
    }),
  ]);

  const [popupOpen, setPopupOpen] = useState(false);

  const [viewPopupOpen, setViewPopupOpen] = useState(false);

  const [editPopupOpen, setEditPopupOpen] = useState(false);

  const [deletePopupOpen, setDeletePopupOpen] = useState(false);

  const [confirmDeletion, setConfirmDeletion] = useState(false);

  const [selectedConcediu, setSelectedConcediu] = useState(null);

  const [confirmationPopupOpen, setConfirmationPopupOpen] = useState(false);

  const [dateConcediu, setDateConcediu] = useState({
    comentariu: "",
    dateConcediu: [],
  });

  function addConcediu() {
    console.log("added");
    // let concedii_initiale = concedii;
    // concedii_initiale.push(
    //   createData('Ion', '2022-01-01' , '2022-01-01', 'In asteptare', <div><span className="flaticon-eye"></span><span className="flaticon-edit"></span><span className="flaticon-delete-1"></span></div>),
    // );
    // setConcedii(concedii_initiale);
    //setConcedii([...concedii, createData('Ion', values[0] , values[1]
    if (dateConcediu.dateConcediu.length >= 1) {
      let data_start_concediu = dateConcediu.dateConcediu[0];
      let data_end_concediu =
        dateConcediu.dateConcediu[dateConcediu.dateConcediu.length - 1];
      let concedii_local = concedii;
      concedii_local.push(
        createData(
          "Ion",
          data_start_concediu,
          data_end_concediu,
          "In asteptare",
          {
            comentariu: dateConcediu.comentariu,
            dateSelectate: dateConcediu.dateConcediu,
          }
        )
      );
      setConcedii(concedii_local);
      setDateConcediu({ comentariu: "", dateConcediu: [] });
      setPopupOpen(false);
    } else {
      alert("Selecteaza cel putin o data pentru concediu!");
    }
  }

  function editConcediu(value) {
    if (selectedConcediu > -1) {
      console.log(selectedConcediu);
      let data_start_concediu = dateConcediu.dateConcediu[0];
      let data_end_concediu =
        dateConcediu.dateConcediu[dateConcediu.dateConcediu.length - 1];
      let concedii_local = concedii;
      concedii_local.splice(value, 1);
      concedii_local.push(
        createData(
          "Corina",
          data_start_concediu,
          data_end_concediu,
          "In asteptare",
          {
            comentariu: dateConcediu.comentariu,
            dateSelectate: dateConcediu.dateConcediu,
          }
        )
      );
      setDateConcediu({ comentariu: "", dateConcediu: [] });
      setSelectedConcediu(null);
      setConcedii(concedii_local);
    }
  }

  function deleteConcediuFromList(value) {
    // verificam daca exista un element la acel index in concedii
    if (selectedConcediu > -1) {
      console.log(selectedConcediu);
      let concedii_local = concedii;
      concedii_local.splice(value, 1);
      setSelectedConcediu(null);
      setConcedii(concedii_local);
    }

    // luam lista de concedii la nivel local si o modificam

    // stergem elementul din lista, bazat pe index

    // actualizam state-ul pentru concedii, cu lista locala
  }

  function formatData(userDate = new Date()) {
    let year = userDate.getFullYear();
    let month = userDate.getMonth() + 1;
    if (month.toString().length == 1) {
      month = '0' + month;
    }
    let day = userDate.getDate();
    return [day, month, year].join('.');
  }

  return (
    <div>
      <div className="col-xl-4" style={{ minWidth: "80%" }}>
        <ButtonComponent onClick={() => setPopupOpen(true)} />
        <div className="row dashboard_row__">
          <div className="dialog_box_dashboard">
            <Dialog
              className="dialog_bigger_width"
              style={
                window.innerWidth > 700
                  ? {
                      padding: "25px 35px 15px 35px",
                    }
                  : { margin: 0 }
              }
              aria-labelledby="customized-dialog-title"
              open={popupOpen}
            >
              <MuiDialogTitle disableTypography>
                <div className="popup-top">
                  <Typography variant="h5">New Request</Typography>

                  <CloseRequestButton onClick={() => setPopupOpen(false)} />
                </div>
                <div>
                  <Divider variant="fullWidth" />
                </div>
              </MuiDialogTitle>
              <DialogContent>
                <TextField
                  id="outlined-full-width"
                  label="Comment"
                  // helperText={"Left characters: " + (255 - commentBox.length)}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  multiline={true}
                  rowsMax={9}
                  rows={8}
                  onChange={(e) => {
                    let dateCon = dateConcediu;
                    dateCon.comentariu = e.target.value;
                    setDateConcediu(dateCon);
                  }}
                />
                <Typography variant="h6">Select Dates</Typography>
                <hr />
                <div className="popup-bottom">
                  <DatePicker
                    multiple
                    plugins={[<DatePanel />]}
                    onChange={(date) => {
                      let array_Dates = [];
                      date.map((el) => {
                        let day = el.day;
                        let month = el.month.number;
                        if (month.toString().length == 1) {
                          month = "0" + month;
                        }
                        let year = el.year;
                        array_Dates.push(year + "/" + month + "/" + day);
                      });
                      let dateCon_initial = dateConcediu;
                      dateCon_initial.dateConcediu = array_Dates;
                      setDateConcediu(dateCon_initial);
                    }}
                  />

                  <SubmitButton onClick={addConcediu} />
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </div>

        <div className="kt-portlet__body" style={{ padding: "0" }}>
          <Paper className={"__concedii_table_root"}>
            <Table className={"__concedii_table"} aria-label="simple table">
              <TableHead>
                <TableRow className={"__concedii_row_"}>
                  <TableCell className={"__concedii_header_"}>
                    Angajat
                  </TableCell>
                  <TableCell className={"__concedii_header_"}>
                    Data Start
                  </TableCell>
                  <TableCell className={"__concedii_header_"}>
                    Data Sfarsit
                  </TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actiuni</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {concedii.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.angajat}
                    </TableCell>
                    <TableCell
                      className={"__concedii_cell_"}
                      format="ddd, MMM. DD, YYYY"
                    >
                      {formatData(new Date(row.datastart))}
                    </TableCell>
                    <TableCell
                      className={"__concedii_cell_"}
                      format="ddd, MMM. DD, YYYY"
                    >
                      {formatData(new Date(row.datasfarsit))}
                    </TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>
                      <div>
                        <span
                          className="flaticon-eye"
                          onClick={() => {
                            setSelectedConcediu(index);
                            setViewPopupOpen(true);
                          }}
                          style={{ paddingLeft: "5px" }}
                        ></span>
                        <span
                          className="flaticon-edit"
                          onClick={() => {
                            setSelectedConcediu(index);
                            setEditPopupOpen(true);
                          }}
                          style={{ paddingLeft: "5px" }}
                        ></span>
                        <span
                          className="flaticon-delete-1"
                          onClick={() => {
                            setSelectedConcediu(index);
                            setDeletePopupOpen(true);
                          }}
                          style={{ paddingLeft: "5px" }}
                        ></span>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
      </div>
      <Dialog
        className="dialog_bigger_width"
        style={
          window.innerWidth > 700
            ? {
                padding: "25px 35px 15px 35px",
              }
            : { margin: 0 }
        }
        aria-labelledby="customized-dialog-title"
        open={viewPopupOpen}
      >
        <MuiDialogTitle disableTypography>
          <div className="popup-top">
            <Typography variant="h5">View request</Typography>

            <CloseRequestButton onClick={() => setViewPopupOpen(false)} />
          </div>
          <div>
            <Divider variant="fullWidth" />
          </div>
        </MuiDialogTitle>
        <DialogContent>
          {selectedConcediu != null && (
            <>
              <p>Comentariu:</p>
              <p>{concedii[selectedConcediu].extra.comentariu}</p>
              <p>Selected dates:</p>
              <p>{concedii[selectedConcediu].extra.dateSelectate.join(", ")}</p>
            </>
          )}
        </DialogContent>
      </Dialog>
      <Dialog
        className="dialog_bigger_width"
        style={
          window.innerWidth > 700
            ? {
                padding: "25px 35px 15px 35px",
              }
            : { margin: 0 }
        }
        aria-labelledby="customized-dialog-title"
        open={editPopupOpen}
      >
        <MuiDialogTitle disableTypography>
          <div className="popup-top">
            <Typography variant="h5">Edit request</Typography>

            <CloseRequestButton onClick={() => setEditPopupOpen(false)} />
          </div>
          <div>
            <Divider variant="fullWidth" />
          </div>
        </MuiDialogTitle>
        <DialogContent>
          <TextField
            id="outlined-full-width"
            label="Comment"
            //helperText={"Left characters: " + (255 - commentBox.length)}
            fullWidth
            margin="normal"
            variant="outlined"
            multiline={true}
            rowsMax={9}
            rows={8}
            value={
              selectedConcediu != null &&
              concedii[selectedConcediu].extra.comentariu
            }
            onChange={(e) => {
              let dateCon = dateConcediu;
              dateCon.comentariu = e.target.value;
              setDateConcediu(dateCon);
            }}
          />
          <Typography variant="h6">Select New Dates</Typography>
          <hr />
          <div className="popup-bottom">
            <DatePicker
              multiple
              plugins={[<DatePanel />]}
              value={
                selectedConcediu != null &&
                concedii[selectedConcediu].extra.dateSelectate.map((el) => {
                  console.log(el);
                  console.log(new Date(Date.parse(el)));
                  return new Date(Date.parse(el));
                })
              }
              onChange={(date) => {
                let array_Dates = [];
                date.map((el) => {
                  let day = el.day;
                  let month = el.month.number;
                  if (month.toString().length == 1) {
                    month = "0" + month;
                  }
                  let year = el.year;
                  array_Dates.push(year + "/" + month + "/" + day);
                });
                let dateCon_initial = dateConcediu;
                dateCon_initial.dateConcediu = array_Dates;
                setDateConcediu(dateCon_initial);
              }}
            />

            <SubmitButton onClick={editConcediu} />
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        className="dialog_bigger_width"
        style={
          window.innerWidth > 700
            ? {
                padding: "25px 35px 15px 35px",
              }
            : { margin: 0 }
        }
        aria-labelledby="customized-dialog-title"
        open={deletePopupOpen}
      >
        <MuiDialogTitle disableTypography>
          <div className="popup-top">
            <Typography variant="h5">Delete request</Typography>
            <CloseRequestButton onClick={() => setDeletePopupOpen(false)} />
          </div>
          <div>
            <Divider variant="fullWidth" />
          </div>
        </MuiDialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this request?</p>
          <YesButton
            onClick={() => {
              deleteConcediuFromList(selectedConcediu);
              setDeletePopupOpen(false);
            }}
          />
          <NoButton />
        </DialogContent>
      </Dialog>
      <Dialog
        className="dialog_bigger_width"
        style={
          window.innerWidth > 700
            ? {
                padding: "25px 35px 15px 35px",
              }
            : { margin: 0 }
        }
        aria-labelledby="customized-dialog-title"
        open={confirmDeletion}
      >
        <MuiDialogTitle disableTypography>
          <div className="popup-top">
            <Typography variant="h5">
              The request was successfully deleted.
            </Typography>

            <CloseRequestButton onClick={() => setConfirmDeletion(false)} />
          </div>
          <div>
            <Divider variant="fullWidth" />
          </div>
        </MuiDialogTitle>
      </Dialog>
    </div>
  );
}
