/* eslint-disable no-restricted-imports */
import React, { useContext } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";

import { makeStyles } from "@material-ui/core/styles";
import { Chip, Avatar, Paper } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import { useSubHeaderDispatch } from "../LayoutContext";
import HorizontalScroll from "react-scroll-horizontal";
import objectPath from "object-path";
import { useDispatch, useSelector } from "react-redux";
import * as builder from "../../ducks/builder";

const useStyles1 = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing(1)
  }
}));
const useStyles2 = makeStyles(theme => ({
  year_dropdown: {
    "&>button": {
      width: "75px",
      display: "flex!important",
      justifyContent: "center"
    }
  },
  month_dropdown: {
    "&>button": {
      width: "115px",
      display: "flex!important",
      justifyContent: "center"
    }
  }
}));
const month_names = {
  "1": "January",
  "2": "February",
  "3": "March",
  "4": "April",
  "5": "May",
  "6": "June",
  "7": "July",
  "8": "August",
  "9": "September",
  "10": "October",
  "11": "November",
  "12": "December"
};

export default function HeaderRightSubMenuListReports(props) {
  //some backup styles
  const classes = useStyles2();

  const { filter_ } = useSelector(state => ({
    filter_: builder.selectors.getSubHeaderItems(state, "reports")[0] || {
      filter_selected: {
        year: "",
        month: ""
      },
      filter: {}
    }
  }));
  const dispatch = useDispatch();
  function set_filter_reports_subheader({ index, value }) {
    dispatch(builder.actions.set_filter_reports_subheader({ index, value }));
  }
  const { filter, filter_selected } = filter_;
  const arr = [0];
  return (
    <div>
      <div style={{ display: "flex", paddingTop: "2px" }}>
        {Object.keys(filter).length > 0 ? (
          <DropdownButton
            id="dropdown-item-button"
            title={month_names[filter_selected.month] || "  "}
            className={classes.month_dropdown}
          >
            {filter[filter_selected.year]
              ? filter[filter_selected.year].map(month => (
                  <Dropdown.Item
                    as="button"
                    onClick={() => {
                      set_filter_reports_subheader({
                        index: 0,
                        value: {
                          ...filter_,
                          filter_selected: {
                            year: filter_selected.year,
                            month: month
                          }
                        }
                      });
                    }}
                  >
                    {month_names[month]}
                  </Dropdown.Item>
                ))
              : ""}
          </DropdownButton>
        ) : (
          ""
        )}
        {Object.keys(filter).length > 0 ? (
          <DropdownButton
            id="dropdown-item-button"
            title={filter_selected.year || "    "}
            className={classes.year_dropdown}
          >
            {Object.keys(filter)
              .reverse()
              .map(year => (
                <Dropdown.Item
                  as="button"
                  onClick={() => {
                    set_filter_reports_subheader({
                      index: 0,
                      value: {
                        ...filter_,
                        filter_selected: { year, month: filter[year][0] }
                      }
                    });
                  }}
                >
                  {year}
                </Dropdown.Item>
              ))}
          </DropdownButton>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
