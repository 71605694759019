import objectPath from "object-path";
import { put, takeLatest } from "redux-saga/effects";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as pack_ from "../../../package.json";

import LayoutConfig from "../layout/LayoutConfig";
import MenuConfig from "../layout/MenuConfig";
import {status_ids_} from "../utils/utils";
import actionTypes from "./builderActions";
import IssuesItem from "../../app/pages/home/reusableFields/IssuesItem";
const issue_priority_map = {
  "Highest":"a",
  "High":"b",
  "Medium":"c",
  "Low":"d",
  "Lowest":"e"
};
const issue_status_names = {
  
}
function sort_function(is1, is2) {
  if(objectPath.get(is1, "fields.priority.name") && objectPath.get(is2, "fields.priority.name"))
  {
    if(issue_priority_map[is1.fields.priority.name] < issue_priority_map[is2.fields.priority.name])
    {
      return -1;
  
    }
    if(issue_priority_map[is1.fields.priority.name] < issue_priority_map[is2.fields.priority.name])
    {
      return 1;
      
    }
    return 0;
  }
  return 0;
}
export const selectors = {
  getClasses: (store, params) => {
    const { htmlClassServiceObjects } = store.builder;

    return htmlClassServiceObjects
      ? htmlClassServiceObjects.getClasses(params.path, params.toString)
      : "";
  },
  getAttributes: (store, params) => {
    if (params.path) {
      // if path is specified, get the value within object
      const { htmlClassServiceObjects } = store.builder;

      return htmlClassServiceObjects
        ? htmlClassServiceObjects.getAttributes(params.path)
        : [];
    }
    return [];
  },
  getDashboardHeaderProjects: state => {
    const menuConfigItems =
      objectPath.get(state, "builder.menuConfig.header.items") || [];
    const page_items_submenu =
      menuConfigItems.find(item => item.page === "dashboard") || {};
    const payload = objectPath.get(page_items_submenu, "items") || [];
    return payload;
  },
  getSubHeaderItems: (state, pageName) => {
    const menuConfigItems =
      objectPath.get(state, "builder.menuConfig.header.items") || [];
    const page_items_submenu =
      menuConfigItems.find(item => item.page === pageName) || {};
    const payload = objectPath.get(page_items_submenu, "items") || [];
    return payload;  
  },
  getConfig: (state, path) => {
    const { layoutConfig } = state.builder;
    if (path) {
      // if path is specified, get the value within object
      return objectPath.get(layoutConfig, path);
    }
    return "";
  },
  getPageConfigHeaderSubmenu: (state, pageName, path) => {
    const menuConfigItems =
      objectPath.get(state, "builder.menuConfig.header.items") || [];
    const page_items_submenu =
      menuConfigItems.find(item => item.page === pageName) || {};
    const payload = objectPath.get(page_items_submenu, path) || {};
    return payload;
  },
  getPageContentConfig: (state, path) => {
    const payload = objectPath.get(state.builder, path) || null;
    return payload;
  },
  getLogo: ({ builder: { layoutConfig } }) => {
    const menuAsideLeftSkin = objectPath.get(layoutConfig, "brand.self.skin");
    // set brand logo
    const logoObject = objectPath.get(layoutConfig, "self.logo");
    let logo;
    if (typeof logoObject === "string") {
      logo = logoObject;
    }

    if (typeof logoObject === "object") {
      logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
    }

    if (typeof logo === "undefined") {
      try {
        const logos = objectPath.get(this.layoutConfig, "self.logo");
        logo = logos[Object.keys(logos)[0]];
      } catch (e) {}
    }
    return logo;
  },
  getStickyLogo: store => {
    const { layoutConfig } = store.builder;
    let logo = objectPath.get(layoutConfig, "self.logo.sticky");
    if (typeof logo === "undefined") {
      logo = selectors.getLogo(store);
    }
    return logo + "";
  }
};

const initialState = {
  menuConfig: MenuConfig,
  layoutConfig: LayoutConfig,
  htmlClassServiceObjects: undefined,
  LoadingData: {
    LoadingPage: true
  },
  version: 2
};
const find_index_of_object_with_property_name = (array_, property, value) => {
  for (let i = 0; i < array_.length; i++) {
    if (array_[i][property] === value) {
      return i;
    }
  }
  return -1;
};

export const reducer = persistReducer(
   {
    storage,
    key: "build-demo1",
    blacklist: ["htmlClassServiceObjects", 'menuConfig']
  },
  (state = initialState, { type, payload }) => {
    let new_issues,
      new_state,
      header_items_,
      new_subHeaderSelectedIds = {};
      
    if(pack_.version !== localStorage.getItem("version_"))
    {
      console.log(pack_.version, localStorage.getItem("version_"));
      localStorage.setItem("version_", pack_.version);
      return initialState;
    }
    switch (type) {
      case actionTypes.RESET_STATE:
        return payload;
      case actionTypes.SET_LOADING_PAGE:
        return {
          ...state,
          LoadingData: {
            ...state.LoadingData,
            LoadingPage: payload
          }
        };

      case actionTypes.SET_PAGE_CONTENT_CONFIG:
        if (objectPath.get(payload, "dashboard.issues")) {
          payload.dashboard.issues = payload.dashboard.issues.sort(sort_function);
        }
        return {
          ...state,
          pageContentConfig: payload
        };
      //....................................
      case actionTypes.SetMenuConfig:
        return { ...state, menuConfig: payload };

      case actionTypes.SetLayoutConfigs:
        return { ...state, layoutConfig: payload };

      case actionTypes.SetLayoutConfigsWithPageRefresh: {
        return { ...state, layoutConfig: payload };
      }
      case actionTypes.SetHtmlClassService:
        return { ...state, htmlClassServiceObjects: payload };

      //TASKS.............................
      case actionTypes.PAUSE_RUNNING_TASK:
        return {
          ...state,
          pageContentConfig: {
            ...state.pageContentConfig,
            dashboard: {
              ...state.pageContentConfig.dashboard,
              runningTask: {
                ...state.pageContentConfig.dashboard.runningTask,
                time: payload.time,
                isRunning: false
              }
            }
          }
        };
      case actionTypes.START_TASK:
        return {
          ...state,
          pageContentConfig: {
            ...state.pageContentConfig,
            dashboard: {
              ...state.pageContentConfig.dashboard,
              runningTask: payload.runningTask
            }
          }
        };
      case actionTypes.REMOVE_TASK:
        const { task_id } = payload;
        const issues = state.pageContentConfig.dashboard.issues;
        new_issues = issues.filter(issue => {
          return issue.id !== task_id;
        });
        return {
          ...state,
          pageContentConfig: {
            ...state.pageContentConfig,
            dashboard: {
              ...state.pageContentConfig.dashboard,
              issues: new_issues
            }
          }
        };
      case actionTypes.ADD_TASK:
        const { new_task } = payload;
        new_issues = state.pageContentConfig.dashboard.issues || [];
        new_issues.push(new_task);
        new_issues = new_issues.sort(sort_function);

        return {
          ...state,
          pageContentConfig: {
            ...state.pageContentConfig,
            dashboard: {
              ...state.pageContentConfig.dashboard,
              issues: new_issues
            }
          }
        };
      case actionTypes.CHANGE_ISSUE_STATUS:
        const { new_status_name } = payload;
        new_issues = state.pageContentConfig.dashboard.issues || [];
        for (let i = 0; i < new_issues.length; i++) {
          if (new_issues[i].id === payload.task_id) {
            new_issues[i].fields.status.id = status_ids_[new_status_name];
            new_issues[i].fields.status.name = new_status_name;
            break;
          }
        }
        return {
          ...state,
          pageContentConfig: {
            ...state.pageContentConfig,
            dashboard: {
              ...state.pageContentConfig.dashboard,
              issues: new_issues
            }
          }
        };
      case actionTypes.UPDATE_SELECTED_HEADER_ITEMS:
        const { id, val, pageName } = payload;
        if (objectPath.get(state, "pageContentConfig." + pageName)) {
          if (
            objectPath.get(
              state,
              `pageContentConfig.${pageName}.subHeaderSelectedIds`
            )
          ) {
            return {
              ...state,
              pageContentConfig: {
                ...state.pageContentConfig,
                [pageName]: {
                  ...state.pageContentConfig[pageName],
                  subHeaderSelectedIds: {
                    ...state.pageContentConfig[pageName].subHeaderSelectedIds,
                    [id]: val
                  }
                }
              }
            };
          } else {
            return {
              ...state,
              pageContentConfig: {
                ...state.pageContentConfig,
                [pageName]: {
                  ...state.pageContentConfig[pageName],
                  subHeaderSelectedIds: {
                    [id]: val
                  }
                }
              }
            };
          }
        }
      case actionTypes.SET_ALL_ISSUES:
        let { new_issues } = payload;

        new_issues = new_issues.sort(sort_function);

        return {
          ...state,
          pageContentConfig: {
            ...state.pageContentConfig,
            dashboard: {
              ...state.pageContentConfig.dashboard,
              issues: new_issues
            }
          }
        };
      case actionTypes.SET_ALL_SUBHEADER_PROJECTS:
        let { new_projects_header, new_selected_ids } = payload;

        header_items_ =
          objectPath.get(state, "menuConfig.header.items") || [];
        const index_dashboard_page = find_index_of_object_with_property_name(
          header_items_,
          "page",
          "dashboard"
        );
        if (index_dashboard_page > -1) {
          header_items_[index_dashboard_page].items = new_projects_header;
          return {
            ...state,
            menuConfig: {
              ...state.menuConfig,
              header: {
                ...state.menuConfig.header,
                items: header_items_
              }
            },
            pageContentConfig: {
              ...state.pageContentConfig,
              dashboard: {
                ...state.pageContentConfig.dashboard,
                subHeaderSelectedIds: new_selected_ids
              }
            }
          };
        } else {
          return state;
        }
        
      //reports
      case actionTypes.SET_SUBHEADER_REPORTS_FILTER:
        let { index, value } = payload;

        header_items_ =
          objectPath.get(state, "menuConfig.header.items") || [];
        const index_reports_page = find_index_of_object_with_property_name(
          header_items_,
          "page",
          "reports"
        );
        if (index_reports_page > -1) {
          header_items_[index_reports_page].items[index] = value;
          return {
            ...state,
            menuConfig: {
              ...state.menuConfig,
              header: {
                ...state.menuConfig.header,
                items: header_items_
              }
            }
          };
        } else {
          return state;
        }
      case actionTypes.SET_SNACKBAR_MESSAGE:
        return{
          ...state,
          snack_bar: payload.snackBarMessage
        }
      default:
        return state;
    }
  }
);

export const actions = {
  reset_saga: (payload) => ({ payload, type: actionTypes.RESET_STATE }),
  setPageLoading: payload => ({ payload, type: actionTypes.SET_LOADING_PAGE }),
  setMenuConfig: payload => ({ payload, type: actionTypes.SetMenuConfig }),
  setContentPageConfig: payload => ({
    payload,
    type: actionTypes.SET_PAGE_CONTENT_CONFIG
  }),
  setLayoutConfigs: payload => ({
    payload,
    type: actionTypes.SetLayoutConfigs
  }),
  setLayoutConfigsWithPageRefresh: payload => ({
    payload,
    type: actionTypes.SetLayoutConfigsWithPageRefresh
  }),
  setHtmlClassService: payload => ({
    payload,
    type: actionTypes.SetHtmlClassService
  }),
  //TASK ACTIONs
  pauseRunningTask: time => ({
    payload: { time },
    type: actionTypes.PAUSE_RUNNING_TASK
  }),
  startTask: runningTask => ({
    payload: { runningTask },
    type: actionTypes.START_TASK
  }),
  changeIssueStatus: ({ task_id, new_status_name }) => ({
    payload: { new_status_name, task_id },
    type: actionTypes.CHANGE_ISSUE_STATUS
  }),
  selectedItemSubHeader: ({ id, val, pageName }) => ({
    payload: { id, val, pageName },
    type: actionTypes.UPDATE_SELECTED_HEADER_ITEMS
  }),

  removeIssue: task_id => ({
    payload: { task_id },
    type: actionTypes.REMOVE_TASK
  }),
  addNewIssue: new_task => ({
    payload: { new_task },
    type: actionTypes.ADD_TASK
  }),
  setAllIssues: ({ new_issues }) => ({
    payload: { new_issues },
    type: actionTypes.SET_ALL_ISSUES
  }),
  updateProjectsSubheader: ({ new_projects_header, new_selected_ids }) => ({
    payload: { new_projects_header, new_selected_ids },
    type: actionTypes.SET_ALL_SUBHEADER_PROJECTS
  }),

  //reports
  set_filter_reports_subheader: ({ index, value }) => ({
    payload: { index, value },
    type: actionTypes.SET_SUBHEADER_REPORTS_FILTER
  }),
  //snackbar
  set_snack_bar: ({ snackBarMessage }) => ({
    payload: { snackBarMessage },
    type: actionTypes.SET_SNACKBAR_MESSAGE
  })
};
export function* saga() {
  yield takeLatest(actionTypes.RESET_STATE, function* reset_saga(action) {
    yield put(actions.reset_saga(initialState));
  });
}