import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "../../crud/auth.crud";
import * as routerHelpers from "../../router/RouterHelpers";
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";
import objectPath from "object-path";


export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined
};
export const selectors = {
  getAuthInfo: (store, path) => {
    return objectPath.get(store, path);
  }
};
export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
      switch (action.type) {
        case actionTypes.Login: {
          const { authToken } = action.payload;

          return { authToken, user: undefined };
        }

        case actionTypes.Register: {
          const { authToken } = action.payload;

          return { authToken, user: undefined };
        }

        case actionTypes.Logout: {
          routerHelpers.forgotLastLocation();
          return initialAuthState;
        }

        case actionTypes.UserLoaded: {
          let { user } = action.payload;
          return { ...state, user };
        }

        default:
          return state;
      }
    }
);

export const actions = {
  login: data => ({ type: actionTypes.Login, payload: data }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () =>{ return { type: actionTypes.Logout }},
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga(action) {
    const user = {
      ...action.payload,
      account_id:action.payload.accountId,
      id: 1,
      username: action.payload.displayName || '',
      password: "demo",
      email: action.payload.emailAddress,
      accessToken: undefined,
      refresh_token: action.payload.refresh_token,
      cloud_id: action.payload.cloud_id,
      roles: [1], // Administrator
      pic: objectPath.get(action.payload, "avatarUrls.48x48") || toAbsoluteUrl("/media/users/300_25.jpg"),
      fullname: action.payload.displayName || '',
      occupation: "CEO",
      companyName: "Keenthemes",
      phone: "456669067890",
      address: {
        addressLine: "L-12-20 Vertex, Cybersquare",
        city: "San Francisco",
        state: "California",
        postCode: "45000"
      },
      socialNetworks: {
        linkedIn: "https://linkedin.com/admin",
        facebook: "https://facebook.com/admin",
        twitter: "https://twitter.com/admin",
        instagram: "https://instagram.com/admin"
      },

    };
    yield put(actions.fulfillUser(user));
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();

    yield put(actions.fulfillUser(user));
  });
    yield takeLatest(actionTypes.Login, function* loginSaga(action) {
    const user = {
      ...action.payload,
      account_id:action.payload.accountId,
      id: 1,
      username: action.payload.displayName || '',
      password: "demo",
      email: action.payload.emailAddress,
      accessToken: undefined,
      refresh_token: action.payload.refresh_token,
      cloud_id: action.payload.cloud_id,
      roles: [1], // Administrator
      pic: objectPath.get(action.payload, "avatarUrls.48x48") || toAbsoluteUrl("/media/users/300_25.jpg"),
      fullname: action.payload.displayName || '',
      occupation: "CEO",
      companyName: "Keenthemes",
      phone: "456669067890",
      address: {
        addressLine: "L-12-20 Vertex, Cybersquare",
        city: "San Francisco",
        state: "California",
        postCode: "45000"
      },
      socialNetworks: {
        linkedIn: "https://linkedin.com/admin",
        facebook: "https://facebook.com/admin",
        twitter: "https://twitter.com/admin",
        instagram: "https://instagram.com/admin"
      },

    };
    yield put(actions.fulfillUser(user));
  });
}
