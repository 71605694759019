import React, { useMemo, useState } from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import objectPath from "object-path";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";

import { Spinner } from "react-bootstrap";
import ButtonB from "react-bootstrap/Button";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import { metronic } from "../../../../_metronic";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";

import PropTypes, { array } from "prop-types";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import { amber, green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";

//WARNING SNACKBAR MESSAGE...
const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
}));
const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};
function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}
MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};
export default function SnackBar() {
  const dispatch = useDispatch();
  const {snackBarMessage_} = useSelector(state => ({
    snackBarMessage_: metronic.builder.selectors.getPageContentConfig(
      state,
      "snack_bar"
    ) || {
      open: false,
      message: "Atentionare",
      color: "error",
      durration: 6000
    }
  }));
  const [snackBarMessage, setSnackBarMessage] = useState({
    open: false,
    message: "Atentionare",
    color: "error",
    durration: 6000
  });
  useEffect(() => {
    dispatch(metronic.builder.actions.set_snack_bar({ snackBarMessage }));
  }, [snackBarMessage]);

  function closeSnackBarMessage() {
    setSnackBarMessage({
      ...snackBarMessage_,
      open: false
    });
  }

  return (
    <>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={snackBarMessage_.open}
          autoHideDuration={snackBarMessage_.duration}
          onClose={closeSnackBarMessage}
        >
          <MySnackbarContentWrapper
            onClose={closeSnackBarMessage}
            variant={snackBarMessage_.color}
            message={snackBarMessage_.message}
          />
        </Snackbar>
      </div>
    </>
  );
}
