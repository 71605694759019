import React, { useMemo, useState } from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import objectPath from "object-path";
import { Dropdown, DropdownButton, Alert } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { Divider, Button, Typography, Dialog } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Avatar from "@material-ui/core/Avatar";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";

import { Spinner } from "react-bootstrap";
import ButtonB from "react-bootstrap/Button";

import axios from "axios";
import { returnNewToken } from "../../../_metronic/utils/utils";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { metronic } from "../../../_metronic";
import IssuesItem from "./reusableFields/IssuesItem";
import Timer from "./reusableFields/Timer";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";

import PropTypes, { array } from "prop-types";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import { amber, green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";

//my imports...
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { getStorage } from './../../../_metronic/utils/utils';

const styles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    width: "652px",
    padding: "10px 35px 0 35px"
  },
  dialocContent: {
    width: "100%",
    padding: "25px 35px 15px 35px!important"
  },
  divider: {
    width: "100%",
    height: "16px"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  title: {
    marginTop: "5px",
    marginBottom: "8px"
  },
  action_inputs_cont: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "25px 5px"
  },
  select: {
    // width: "200px",
  },
  dropdown_button: {
    width: "100%"
  },
  dropdown_button_child: {
    width: "100%"
  },
  undeInputText: {
    fontSize: "0.85rem",
    margin: "8px 4px 0"
  },
  refresh_tasks_button: {
    width: "100px",
    display: "flex",
    justifyContent: "flex-end",
    height: "100%",
    alignItems: "center",
    "spinner-border": {
      width: "15px",
      height: "15px",
      border: "0.15em solid currentColor"
    },
    "&>button": {
      width: "80px",
      height: "30px"
    },
    "&>div": {
      fontSize: "18px",
      fontWeight: "bold",
      width: "auto",
      height: "30px",
      margin: "0",
      padding: "5px",
      textAlign: "center!important",
      display: "flex",
      justifyContent: "center",
      alignItems: "fle-end",
      "&>div": {
        width: "auto"
      }
    }
  },
  time_avatar_aggregation: {
    width: "100%",
    textAlign: "center",
    fontSize: "12px",
    letterSpacing: "1px",
    fontWeight: "600"
  },
  toolprit_dashboard: {
    top: "-15px",
    padding: "5px 10px",
    fontSize: "12px"
  },
  tooltip_row_items: {
    marginTop: "3px"
  }
}));

function ButtonComponent(props) {
  const { onClick, loading } = props;

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={loading}
    >
      {loading ? (
        <CircularProgress
          style={{ height: "17px", width: "17px", zIndex: "5" }}
          color="primary"
        />
      ) : (
        <>Refresh</>
      )}
    </Button>
  );
}

export default function Reports() {
  const dispatch = useDispatch();
  const { refresh_token, cloud_id, account_id, filters_ } = useSelector(
    state => ({
      filters_: metronic.builder.selectors.getSubHeaderItems(
        state,
        "reports"
      )[0] || {
        filter_selected: {
          year: "",
          month: ""
        },
        filter: {}
      },
      refresh_token: getStorage('jira_refresh_token'),
      cloud_id: metronic.auth.selectors.getAuthInfo(
        state,
        "auth.user.cloud_id"
      ),
      account_id: metronic.auth.selectors.getAuthInfo(
        state,
        "auth.user.accountId"
      )
    })
  );
  const [filter_selected_, set_filter_selected] = useState({year:'', month:''});

  const {filter, filter_selected} = filters_;

  const classes = styles();
  const [is_fetching_reports, set_is_fetching_reports] = useState(false);
  const [reports, set_reports] = useState({});

  function getReports({ year, month }) {
    if(is_fetching_reports === true) return;
    set_is_fetching_reports(true);
    axios
      .get(
        `get-reports.php?year=${year}&month=${month}&account_id=${ account_id}`
      )
      .then(({ data }) => {
        if (data.results !== null) {
          set_reports(data.results);
          if (data.filter) {
            const filter_aux = {
              filter: data.filter || {},
              filter_selected: {
                  year: data.year_selected || "",
                  month: data.month_selected || ""
              }
            };
            set_filter_selected(filter_aux.filter_selected);
            dispatch(
              metronic.builder.actions.set_filter_reports_subheader({index:0, value:filter_aux})
            );
            
          }
        } else {
          console.log("Negative response from server");
        }
        set_is_fetching_reports(false);
      })
      .catch(err => {
        set_is_fetching_reports(false);
        console.log(err, "Couldn't fetch reports");
      });
  }
  useEffect(() => {
    if(filter_selected_.year === '' && filter_selected_.year === "")
    {
      getReports(filter_selected_);
    }
    else
    {
      if(filter_selected_.year !== filter_selected.year || filter_selected_.month !== filter_selected.month){
        getReports(filter_selected);
      }
    }
  }, [filter_selected]);
  useEffect(() => {
      dispatch(
        metronic.builder.actions.set_filter_reports_subheader({index:0, value:{ filter:{}, filter_selected:{ year:'', month: ''} } })
      );
  }, [account_id]);
  useEffect(() => {}, [filter, filter_selected]);
  return (
    <>
      <div className="row">
        <div className="col-xl-4" style={{ minWidth: "65%" }}>
          <div className="kt-portlet kt-portlet--height-fluid to_do_list_container">
            <div
              className="kt-portlet__head"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">Report List</h3>
              </div>
              <div className="kt-portlet__head-label">
                <div className={classes.refresh_tasks_button}>
                  {/* <ButtonComponent
                    // onClick={refreshIssues}
                    // loading={is_fetching_refreshing_issues}
                  /> */}
                </div>
              </div>
            </div>

            <div className="kt-portlet__body" style={{ padding: "0" }}>
              <div className="kt-widget4 reports__">
                {is_fetching_reports === true ? (
                  <div
                    style={{
                      height: "100px",
                      fontWeight: "500",
                      fontSize: "1rem",
                      color: "#595d6e",
                      padding:"20px 25px"
                    }}
                  >
                    <LinearProgress />
                    <br />
                    <LinearProgress color="secondary" />
                  </div>
                ) : reports.length === 0 ? (
                  <div
                    style={{
                      height: "100px",
                      fontWeight: "500",
                      fontSize: "1rem",
                      color: "#595d6e"
                    }}
                  >
                    No reports.
                  </div>
                ) : (
                  <Paper className={"__reports_table_root"}>
                    <Table
                      className={"__reports_table"}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow className={"__reports_row_"}>
                          <TableCell className={"__reports_header_"}>
                            Day
                          </TableCell>
                          <TableCell
                            className={"__reports_header_"}
                            align="right"
                          >
                            Hours
                          </TableCell>
                          <TableCell
                            className={"__reports_header_"}
                            align="right"
                          >
                            Hours and minutes
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.keys(reports).map((index_date, index) => {
                          const row = reports[index_date];
                          if(index < Object.keys(reports).length - 1 )
                          {
                            return (
                              <TableRow
                                key={index_date}
                                className={
                                 `__reports_row_${
                                  row.hours_only < 7
                                    ? " __reports_red_background"
                                    : row.hours_only < 7.5
                                    ? " __reports_yellow_background"
                                    : ""}`
                                }
                              >
                                <TableCell
                                  className={"__reports_cell_"}
                                  component="th"
                                  scope="row"
                                >
                                  <Moment
                                    format="ddd, MMM. DD, YYYY"
                                    date={index_date}
                                  />
                                </TableCell>
                                <TableCell
                                  className={"__reports_cell_"}
                                  align="right"
                                >
                                  {row.hours_only}
                                </TableCell>
                                <TableCell
                                  className={"__reports_cell_"}
                                  align="right"
                                 
                                >
                                  {row.hours +
                                    " hours and " +
                                    row.minutes +
                                    " minutes"}
                                </TableCell>
                              </TableRow>
                            );
                          }
                          return (
                            <TableRow
                              key={index_date}
                              className="__reports_row_ __reports_total_background"
                            >
                              <TableCell
                                className={"__reports_cell_"}
                                component="th"
                                scope="row"
                              >
                                {index_date}
                              </TableCell>
                              <TableCell
                                className={"__reports_cell_"}
                                align="right"
                              >
                                {row.hours_only}
                              </TableCell>
                              <TableCell
                                className={"__reports_cell_"}
                                align="right"
                              >
                                {row.hours +
                                  " hours and " +
                                  row.minutes +
                                  " minutes"}
                              </TableCell>
                            </TableRow>
                          );

                        })}
                      </TableBody>
                    </Table>
                  </Paper>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
