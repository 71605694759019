import React, { Component, Children } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { returnNewToken, status_ids_ } from "../_metronic/utils/utils";
import staticResponse from "../_metronic/layout/ResponseStatic";

import { LayoutSplashScreen } from "../_metronic/layout/LayoutContext";
import Axios from "axios";
import objectPath from "object-path";

class LoadServerDataPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    this.getPageInfo(this.props.location.pathname || "dashboard");
  };
  getDashboardInfo = (response, access_token) => {
    // const response = staticResponse;
    // console.log(response);
    let projects = [];
    let issues = [];
    let projectIds = {}; // object used to check existence only
    //initialize issues and projects
    if (Array.isArray(response.issues)) {
      issues = response.issues;
      for (let i = 0; i < response.issues.length; i++) {
        const item = response.issues[i];
        if (objectPath.get(item, "fields.project.id")) {
          if (!projectIds[item.fields.project.id]) {
            projects.push(item.fields.project);
            projectIds[item.fields.project.id] = 1;
          }
        }
      }
    }
    let runningTask = {
      task_id: undefined,
      isRunning: false
    };
    axios
      .get(`open-task_test.php?account_id=${this.props.account_id}`)
      .then(({ data }) => {
        if ( 
          //  1 === 2 &&
           data.task_id && data.task_id != null ) {
          const alr_running_task_id = data.task_id;
          const alr_running_task_time = data.time_runned || 0;
          //check if the already running task exists or was added after page was loaded
          const exists = issues.find(is => is.id === alr_running_task_id);
          if (exists == undefined) {
            //find the new task pushed them then start
            axios
              .get(
                `get-task.php?cloud_id=${this.props.cloud_id}&task_id=${alr_running_task_id}&access_token=${access_token}`
              )
              .then(response => {
                const new_task = response.data;
                if (new_task.id != null) {
                  runningTask = {
                    task_id: alr_running_task_id,
                    time: alr_running_task_time,
                    isRunning: true
                  };
                  issues.push(new_task);
                  //check if we push its project in header menu
                  if (
                    (status_ids_[objectPath.get(new_task, "fields.status.id")] ===
                      "Ready" ||
                      status_ids_[objectPath.get(new_task, "fields.status.id")] ===
                        "In Progress") &&
                    objectPath.get(new_task, "fields.project.id")
                  ) {
                    if (!projectIds[new_task.fields.project.id]) {
                      projects.push(new_task.fields.project);
                    }
                  }



                  //set up the task list
                  let pageContentConfig = {
                    ...this.props.pageContentConfig,
                    dashboard: {
                      issues: issues,
                      runningTask: runningTask
                    }
                  };
                  //set up the subheader items project bar
                  let header_items = this.props.menuConfig.header.items;
                  header_items[0].items = projects;
                  const menuConfig = {
                    ...this.props.menuConfig,
                    header: {
                      ...this.props.menuConfig.header,
                      items: header_items
                    }
                  };
                  this.props.setMenuConfig(menuConfig);
                  this.props.setContentPageConfig(pageContentConfig);
                  this.props.setPageLoading(false);
                } else {
                  //set up the task list
                  let pageContentConfig = {
                    ...this.props.pageContentConfig,
                    dashboard: {
                      issues: issues,
                      runningTask: runningTask
                    }
                  };
                  //set up the subheader items project bar
                  let header_items = this.props.menuConfig.header.items;
                  header_items[0].items = projects;
                  const menuConfig = {
                    ...this.props.menuConfig,
                    header: {
                      ...this.props.menuConfig.header,
                      items: header_items
                    }
                  };
                  this.props.setMenuConfig(menuConfig);
                  this.props.setContentPageConfig(pageContentConfig);
                  this.props.setPageLoading(false);
                  console.log(
                    "Getting the NEW task that already runs and is NOT in 'In Progress' or 'Ready' data failed."
                  );
                }
              })
              .catch(err => {
                this.props.setPageLoading(false);
                console.log(
                  err,
                  "ERROR : Getting the NEW task that already runs and is NOT in 'In Progress' or 'Ready'."
                );
              });
          } else {
            runningTask = {
              task_id: alr_running_task_id,
              time: alr_running_task_time,
              isRunning: true
            };
            //set up the task list
            let pageContentConfig = {
              ...this.props.pageContentConfig,
              dashboard: {
                issues: issues,
                runningTask: runningTask
              }
            };
            //set up the subheader items project bar
            let header_items = this.props.menuConfig.header.items;
            header_items[0].items = projects;
            const menuConfig = {
              ...this.props.menuConfig,
              header: {
                ...this.props.menuConfig.header,
                items: header_items
              }
            };
            this.props.setMenuConfig(menuConfig);
            this.props.setContentPageConfig(pageContentConfig);
            this.props.setPageLoading(false);
          }
        } else {
          //set up the task list
          let pageContentConfig = {
            ...this.props.pageContentConfig,
            dashboard: {
              issues: issues,
              runningTask: runningTask
            }
          };
          //set up the subheader items project bar
          let header_items = this.props.menuConfig.header.items;
          header_items[0].items = projects;
          const menuConfig = {
            ...this.props.menuConfig,
            header: {
              ...this.props.menuConfig.header,
              items: header_items
            }
          };
          this.props.setMenuConfig(menuConfig);
          this.props.setContentPageConfig(pageContentConfig);
          this.props.setPageLoading(false);
        }
      })
      .catch(err => {
        this.props.setPageLoading(false);
        console.log(err, "error: Task running check failed.");
      });
  };
  getPageInfo = (pathname = "dashboard") => {
    const cloud_id = this.props.cloud_id;
    const refresh_token = this.props.refresh_token;
    pathname = "dashboard";
    switch (pathname) {
      case "dashboard":
        this.props.setPageLoading(true);
        returnNewToken(refresh_token)
          .then(access_token => {
            axios
              .get(
                `get-tasks.php?cloud_id=${cloud_id}&access_token=${access_token}`
              )
              .then(response => {
                if (response.data.issues && Array.isArray(response.data.issues)) {
                  this.getDashboardInfo(response.data, access_token);
                } else {
                  this.props.setPageLoading(false);
                  console.log("Project not found.");
                }
              })
              .catch(err => {
                this.props.setPageLoading(false);
                console.log(err);
              });
          })
          .catch(er => {
            console.log(er);
            this.props.setPageLoading(false);
          });
        break;
      case "reports":
        break;
    }
  };
  componentDidUpdate = prevProps => {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.getPageInfo(this.props.location.pathname);
    }
  };
  render() {
    const { LoadingPage } = this.props;
    return <>{LoadingPage === true ? <LayoutSplashScreen /> : ""}</>;
  }
}
const mapStateToProps = store => ({
  LoadingPage: store.builder.LoadingData.LoadingPage,
  menuConfig: store.builder.menuConfig,
  pageContentConfig: store.builder.pageContentConfig || {},
  refresh_token: objectPath.get(store, "auth.user.refresh_token") || "",
  cloud_id: objectPath.get(store, "auth.user.cloud_id") || "",
  account_id: objectPath.get(store, "auth.user.account_id") || ""
});

export default withRouter(connect(mapStateToProps)(LoadServerDataPage));
