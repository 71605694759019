import React, { Component } from "react";
function preventDefault(e) {
  e.preventDefault();
}
class HeaderScrollHorizontal extends Component {
  constructor(props) {
    super(props);
    // state = {
    //   scrolled_prev:0
    // }
    this.horizontal_scroll = React.createRef();
  }
  onScroll = deltaY => {
    if (this.horizontal_scroll) {
      let scrolled_left = deltaY > 0 ? -50 : 50;
      this.horizontal_scroll.current.scrollTo(this.horizontal_scroll.current.scrollLeft + scrolled_left, 0);
    }

  };
  disableScroll = () => {
    if (window.addEventListener) // older FF
        window.addEventListener('DOMMouseScroll', preventDefault, false);
    document.addEventListener('wheel', preventDefault, {passive: false}); // Disable scrolling in Chrome
  }

  enableScroll = () => {
    
    if (window.removeEventListener)
        window.removeEventListener('DOMMouseScroll', preventDefault, false);
    document.removeEventListener('wheel', preventDefault, {passive: false}); // Enable scrolling in Chrome
}
  render() {
    const { items } = this.props;
    return (
      <div
        onMouseEnter={() => {
          this.disableScroll();
        }}
        onMouseLeave={() => {
          this.enableScroll();
        }}
        className="sub_menu_right_container"
        style={{ width: "calc(100% - 75px" }}
        ref={this.horizontal_scroll}
        onWheel={e => {
          this.onScroll(e.deltaY);
        }}
      >
          {this.props.children}
      </div>
    );
  }
}

export default HeaderScrollHorizontal;
