/**
 * Layout action types, used to filter out dispatched actions.
 */
export const actionTypes = {
    /** Initializes layout state from provided `{ pathname, menuConfig }` action payload.*/
    INIT: "INIT",
    /** Updates current subheader from provided `{ title }` action payload.*/
    SET_SUBHEADER: "SET_SUBHEADER",
    /*Controls splash screen visibility.*/
    SHOW_SPLASH_SCREEN: "SHOW_SPLASH_SCREEN",
    HIDE_SPLASH_SCREEN: "HIDE_SPLASH_SCREEN",
    //subheader
    UPDATE_SUBHEADER_ITEMS: "UPDATE_SUBHEADER_ITEMS"
  };