export default {
  RESET_STATE: 'RESET_STATE',
    SetMenuConfig: "builder/SET_MENU_CONFIG",
    SET_PAGE_CONTENT_CONFIG: 'builder/SET_PAGE_CONTENT_CONFIG',

    SetLayoutConfigs: "builder/SET_LAYOUT_CONFIGS",
    SetLayoutConfigsWithPageRefresh: "builder/SET_LAYOUT_CONFIGS_WITH_PAGE_REFRESH",
    SetHtmlClassService: "builder/SET_HTML_CLASS_SERVICE",
    //set loading whole page when reloading untill server data arrives ( in <LoadingServerData/>)
    SET_LOADING_PAGE: 'SET_LOADING_PAGE',

    PAUSE_RUNNING_TASK: 'PAUSE_RUNNING_TASK',
    START_TASK: 'START_TASK',
    REMOVE_TASK: 'REMOVE_TASK',
    UPDATE_ISSUES: 'ADD_TASK',
    CHANGE_ISSUE_STATUS: 'CHANGE_ISSUE_STATUS',
    UPDATE_SELECTED_HEADER_ITEMS: 'UPDATE_SELECTED_HEADER_ITEMS',
    SET_ALL_ISSUES:'SET_ALL_ISSUES',
    SET_ALL_SUBHEADER_PROJECTS:'SET_ALL_SUBHEADER_PROJECTS',
    SET_SUBHEADER_REPORTS_FILTER: 'SET_SUBHEADER_REPORTS_FILTER',
    SET_SNACKBAR_MESSAGE: 'SET_SNACKBAR_MESSAGE'
  };